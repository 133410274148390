import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React, {useState} from 'react';

export const TeacherView = props => {
  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1.5rem',
          paddingBottom: '0.7rem',
          borderBottom: '2px solid #A8B1B7',
        }}
      >
        <Box style={{display: 'flex', flexDirection: 'column'}}>
          <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>
            {props.teacher_info.teacher_name + ' 선생님'}
          </Box>
          <Box
            style={{
              fontSize: '0.875rem',
              color: '#68737D',
              marginTop: '0.2rem',
            }}
          >
            {props.teacher_info.group_name}
          </Box>
        </Box>
        <Box
          style={{
            fontSize: '0.875rem',
            padding: '0.7rem 1.5rem',
            border: '2px solid #E9EBED',
            borderRadius: '1.9rem',
            backgroundColor:
              props.teacher_info.auth_status == 0 ? '#979797' : 'white',
            color: props.teacher_info.auth_status == 0 ? 'white' : '#7d7d7d',
          }}
        >
          {props.teacher_info.auth_status == 0 ? '인증 대기' : '연결중'}
        </Box>
      </Box>
    </Box>
  );
};
TeacherView.propTypes = {
  teacher_info: PropTypes.object,
};
export default TeacherView;
