import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState} from 'react';
import Pagination from 'view/mypage/Pagination';
import APIUtils from 'common/utils/APIUtils';
import TaskView2 from './TaskView2';

export const FragmentView = () => {
  const authReducer = useSelector(state => state.authReducer);
  const [originalTaskList, setOriginalTaskList] = useState([]);
  const [filteredTaskList, setFilteredTaskList] = useState([]);
  const countPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  function paginationClickEvent(page) {
    setCurrentPage(page);
  }
  function getTaskList() {
    const getTask = async () => {
      try {
        const response = await APIUtils.TaskOngoing(authReducer.student_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getTask().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setOriginalTaskList(res.data.task_list);

        removeFinishedTaskFromLocalStorage(res.data.task_list);
      }
    });
  }
  function getFilteredTask() {
    if (originalTaskList == undefined || originalTaskList.length == 0) {
      setFilteredTaskList([]);
      return;
    }
    let filtered = [...originalTaskList];
    filtered.sort((a, b) => {
      return new Date(b.task_end_time) - new Date(a.task_end_time);
    });
    setFilteredTaskList(filtered);
    setTotalPage(Math.floor((filtered.length - 1) / countPerPage + 1));
  }
  function removeFinishedTaskFromLocalStorage(_originTask) {
    const runningTask = _originTask.map(item => item.task_id.toString());
    for (var i = 0; i < localStorage.length; i++) {
      var foundKey = localStorage.key(i);
      if (foundKey.includes('taskTitle')) {
        var foundTask1 = foundKey.replace('taskTitle', '');
        if (!runningTask.includes(foundTask1)) {
          localStorage.removeItem(foundKey);
        }
      }
      if (foundKey.includes('taskBody')) {
        var foundTask2 = foundKey.replace('taskBody', '');
        if (!runningTask.includes(foundTask2)) {
          localStorage.removeItem(foundKey);
        }
      }
    }
  }
  useEffect(() => {
    getTaskList();
  }, []);
  useEffect(() => {
    getFilteredTask();
  }, [originalTaskList]);
  return (
    <Box>
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>진행 중 과제</Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '1.3rem',
        }}
      />
      <Box
        style={{marginBottom: '1rem', color: '#87929D', fontSize: '0.875rem'}}
      >
        진행 중인 과제입니다. 제출 기한을 반드시 확인하세요.
      </Box>
      <Box style={{display: filteredTaskList.length == 0 ? '' : 'none'}}>
        <Box>진행 중 과제가 없습니다.</Box>
      </Box>
      <Box style={{marginBottom: '2rem'}}>
        {filteredTaskList
          .filter((value, index) => {
            if (Math.floor(index / countPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((value, index) => {
            return (
              <TaskView2
                key={'task' + index}
                // detailEvent={clickTaskDetail}
                task={value}
              />
            );
          })}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};

export default FragmentView;
