import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, TextField, Button} from '@mui/material';
import {
  subtitleBox,
  subtitleLeftBox,
  subtitleRightBox,
} from 'view/result/Styles';
import backgroundWings from 'asset/imageV2/background_wings.png';

const ItemView = props => {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        style={{
          backgroundColor: 'rgba(255, 153, 0, 0.1)',
          boxShadow: '2px 5px 25px 4px rgba(224, 161, 65, 0.12)',
          border: '2px solid #FCE9CE',
          borderRadius: '1.25rem',
          maxWidth: '20rem',
          width: '100%',
          // aspectRatio: '1',
          height: '15rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          style={{
            color: 'white',
            backgroundColor: '#FF9900',
            borderRadius: '6.25rem',
            padding: '0.625rem 1.5625rem',
          }}
        >
          총점
        </Box>
        <Box
          style={{
            backgroundImage: `url(${backgroundWings})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            // backgroundColor: 'red',
            padding: '1rem 0rem 2rem 0rem',
            maxWidth: '20rem',
            width: '100%',
            fontWeight: '700',
            color: '#ff9900',
            fontSize: '3.5rem',
            marginBottom: '1rem',
            textAlign: 'center',
          }}
        >
          {props.tScore}
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  tScore: PropTypes.number,
};
export default ItemView;
