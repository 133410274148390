import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, Button, Box, IconButton} from '@mui/material';
import ABCItem from './ABCItem';
import ACItem from './ACItem';
import CItem from './CItem';
import ParagraphItem from './ParagraphItem';

const ItemView = props => {
  if (props.type == 'ABC') {
    return (
      <ABCItem
        A={props.A}
        B={props.B}
        C={props.C}
        subtype={props.subtype}
        commentID={props.commentID}
      />
    );
  } else if (props.type == 'AC') {
    return (
      <ACItem
        A={props.A}
        C={props.C}
        subtype={props.subtype}
        commentID={props.commentID}
      />
    );
  } else if (props.type == 'C') {
    return (
      <CItem C={props.C} subtype={props.subtype} commentID={props.commentID} />
    );
  } else if (props.type == 'paragraph') {
    return (
      <ParagraphItem paragraphIndex={props.paragraphIndex} text={props.text} />
    );
  }
  return <></>;
};
ItemView.propTypes = {
  paragraphIndex: PropTypes.number,
  idx: PropTypes.number,
  commentID: PropTypes.number,
  type: PropTypes.string,
  A: PropTypes.string,
  B: PropTypes.string,
  C: PropTypes.string,
  subtype: PropTypes.string,
  text: PropTypes.array,
};
export default ItemView;
