import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import {StringUtils} from 'common/utils/StringUtils';
import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {useSelector} from 'react-redux';

export const TaskView = props => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const dateLeft = StringUtils.getDateDiff(props.task.task_end_time);

  function showLabel() {
    if (props.task.task_status == '진행중') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: 'white',
              backgroundColor: '#68737D',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            D-{dateLeft}
          </Box>
          <Box
            style={{
              color: '#FF9900',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #D8DCDE',
            }}
          >
            진행중
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
        </Box>
      );
    } else if (props.task.task_status == '대기') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#68737D',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #68737D',
            }}
          >
            대기
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
        </Box>
      );
    } else if (props.task.task_status == '종료') {
      return (
        <Box
          style={{
            color: '#87929D',
            backgroundColor: '#E9EBED',
            width: 'fit-content',
            padding: '0.125rem 0.375rem',
            borderRadius: '0.3125rem',
            fontSize: '0.875rem',
            fontWeight: 'bolder',
          }}
        >
          종료
        </Box>
      );
    }
  }

  function showButton() {
    if (props.task.task_status == '진행중') {
      if (props.task.task_submit) {
        return (
          <Button
            style={{
              color: '#00C500',
              fontSize: '0.875rem',
              border: '1px solid #00C500',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '7rem',
            }}
            onClick={() => {
              APIUtils.LoggerLog(
                authReducer.user_id,
                'keewil',
                'info',
                window.location.pathname,
                'navigate_writing2',
              );
              navigate('/writing2', {
                state: {
                  task_id: props.task.task_id,
                },
              });
            }}
          >
            수정하기
          </Button>
        );
      }
      return (
        <Button
          style={{
            color: '#FF9900',
            fontSize: '0.875rem',
            border: '1px solid #FF9900',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '7rem',
          }}
          onClick={() => {
            APIUtils.LoggerLog(
              authReducer.user_id,
              'keewil',
              'info',
              window.location.pathname,
              'navigate_writing2',
            );
            navigate('/writing2', {
              state: {
                task_id: props.task.task_id,
              },
            });
          }}
        >
          작성하기
        </Button>
      );
    }
    if (!props.task.task_submit) {
      return (
        <Button
          disabled={props.task.task_share == '1'}
          style={{
            color: 'black',
            fontSize: '0.875rem',
            border: '1px solid var(--gray-2, #E6E8EB)',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '7rem',
          }}
          onClick={() => {
            APIUtils.LoggerLog(
              authReducer.user_id,
              'keewil',
              'info',
              window.location.pathname,
              'navigate_writing2',
            );
            navigate('/writing2', {
              state: {
                task_id: props.task.task_id,
              },
            });
          }}
        >
          {props.task.task_share == '1' ? '미제출' : '늦은 제출'}
        </Button>
      );
    } else if (props.task.task_share) {
      return (
        <Box style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
          <Button
            style={{
              display: props.task.keewichat_url == '' ? 'none' : '',
              backgroundColor: 'white',
              color: '#00C500',
              border: '1px solid #00C500',
              fontSize: '0.875rem',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '7rem',
            }}
            onClick={() => window.open(props.task.keewichat_url)}
          >
            키위 챗
          </Button>
          <Button
            style={{
              backgroundColor: '#00C500',
              color: 'white',
              fontSize: '0.875rem',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '7rem',
            }}
            onClick={() => {
              APIUtils.LoggerLog(
                authReducer.user_id,
                'keewil',
                'info',
                window.location.pathname,
                'navigate_result',
              );
              navigate('/result', {
                state: {
                  task_id: props.task.task_id,
                },
              });
            }}
          >
            결과 확인
          </Button>
        </Box>
      );
    }
    return (
      <Box style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
        <Button
          style={{
            display: props.task.keewichat_url == '' ? 'none' : '',
            backgroundColor: 'white',
            color: '#00C500',
            border: '1px solid #00C500',
            fontSize: '0.875rem',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '7rem',
          }}
          onClick={() => window.open(props.task.keewichat_url)}
        >
          키위 챗
        </Button>
        <Button
          disabled={true}
          style={{
            backgroundColor: '#979797',
            color: 'white',
            fontSize: '0.875rem',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '7rem',
          }}
        >
          제출 완료
        </Button>
      </Box>
    );
  }
  return (
    <Box
      style={{
        display: 'flex',
        // justifyContent: 'start',
        marginTop: '1.5rem',
        paddingBottom: '1.7rem',
        borderBottom: '2px solid #A8B1B7',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.75rem',
          flex: 1,
        }}
      >
        <Box style={{display: 'flex', gap: '0.5rem'}}>{showLabel()}</Box>
        <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>
          {'[' + props.task.task_janre + '] ' + props.task.task_name}
        </Box>
        <Box
          style={{
            display: 'initial',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '40vw',
          }}
        >
          {props.task.task_message}
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        {showButton()}
      </Box>
    </Box>
  );
};
TaskView.propTypes = {
  task: PropTypes.object,
  detailEvent: PropTypes.func,
};
export default TaskView;
