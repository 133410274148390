import {createTheme} from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1b5e20',
      contrastText: '#fff',
    },
    info: {
      main: '#fa8e2b',
      contrastText: '#fff',
    },
    warning: {
      main: '#fa8e2b',
      contrastText: '#fff',
    },
    secondary: {
      main: '#4e8c21',
      contrastText: '#fff',
    },
    success: {
      main: '#4e8c21',
      contrastText: '#fff',
    },
    transparant: {
      main: 'rgba(255,255,255,0)',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['NotoSans', 'Jua', 'LeeSeoyun'].join(','),
    // fontSize: 14,
    // fontWeightLight: 300,
    // fontWeightRegular: 400,
    // fontWeightMedium: 500,
    // fontWeightBold: 800,
    // button: {
    //   textTransform: 'none',
    // },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
              @font-face {
                font-family: 'NotoSans';
                font-display: swap;
                font-weight: 400;
               }
            `,
    },
  },
});

export default theme;
