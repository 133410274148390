import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Button, Box, Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import HeaderView from 'view/common/Header';
import backgroundMain1 from 'asset/imageV2/background_main1.svg';
import iconArrowRight from 'asset/imageV2/icon_arrow_right.png';
import iconCert1 from 'asset/imageV2/icon_cert1.svg';
import iconCert2 from 'asset/imageV2/icon_cert2.png';
import iconCert3 from 'asset/imageV2/icon_cert3.png';
import iconCert4 from 'asset/imageV2/icon_cert4.png';
import iconCert5 from 'asset/imageV2/icon_cert5.png';
import iconCert6 from 'asset/imageV2/icon_cert6.png';
import imgMain1 from 'asset/imageV2/image_main1.png';
import imgMain2 from 'asset/imageV2/image_main2.png';
import imgMain3 from 'asset/imageV2/image_main3.png';
import {mainColoredBtn, iconBox} from 'view/main/Styles';
import FooterView from 'view/common/Footer';
import SideOverlay1 from 'view/common/SideOverlay1';
import APIUtils from 'common/utils/APIUtils';
import {GetRunningPopup} from 'common/utils/GraphQLUtils';

const MainPage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const navigate = useNavigate();
  const [expand1, setExpand1] = useState(false);
  const [expand2, setExpand2] = useState(false);
  function showMainButton() {
    if (authReducer.isLogin) {
      return (
        <Button
          variant="standard"
          style={mainColoredBtn}
          onClick={() => {
            APIUtils.LoggerLog(
              authReducer.user_id,
              'keewil',
              'info',
              window.location.pathname,
              'navigate_mypage',
            );
            navigate('/mypage');
          }}
        >
          {'마이페이지'}
          <img
            src={iconArrowRight}
            alt="iconArrowRight"
            style={{marginLeft: '1rem', width: '1.5rem'}}
          />
        </Button>
      );
    }
    return (
      <Button
        variant="standard"
        style={mainColoredBtn}
        onClick={() => {
          APIUtils.LoggerLog(
            authReducer.user_id,
            'keewil',
            'info',
            window.location.pathname,
            'navigate_login',
          );
          navigate('/login');
        }}
      >
        {'회원가입/로그인'}
        <img
          src={iconArrowRight}
          alt="iconArrowRight"
          style={{marginLeft: '1rem', width: '1.5rem'}}
        />
      </Button>
    );
  }
  return (
    <Box
      style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
    >
      <GetRunningPopup />
      <SideOverlay1 />
      <HeaderView />

      <Box
        style={{
          marginTop: '3rem',
          width: '70%',
          maxWidth: '80rem',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box style={{flex: 4}}>
          <Box
            style={{
              fontSize: '1rem',
              color: '#49545c',
              marginTop: '12rem',
            }}
          >
            재미있는 글쓰기, 키위런
          </Box>
          <Box
            style={{
              fontSize: '2.3rem',
              color: '#2f3941',
              fontWeight: '700',
              marginTop: '1rem',
            }}
          >
            <span style={{color: '#00C500'}}>쉽게</span> 쓰고
          </Box>
          <Box
            style={{fontSize: '2.3rem', color: '#2f3941', fontWeight: '700'}}
          >
            <span style={{color: '#00C500'}}>함께</span> 고치자!
          </Box>
          <Box style={{marginTop: '2rem', marginBottom: '5rem'}}>
            {showMainButton()}
          </Box>
        </Box>
        <Box
          style={{
            flex: 6,
            position: 'relative',
          }}
        >
          <img
            src={imgMain1}
            alt="imgMain1"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              objectFit: 'contain',
              objectPosition: 'center center',
            }}
          />
          <img
            src={backgroundMain1}
            alt={'backgroundMain1'}
            style={{
              width: '120%',
              height: '100%',
              zIndex: '-1',
              position: 'absolute',
              objectFit: 'contain',
              objectPosition: 'center center',
              marginLeft: '-20%',
            }}
          />
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '3rem',
          width: '80%',
          maxWidth: '80rem',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginTop: '4rem',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert6}
                alt="iconCert1"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>국가공헌대상</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              교육부 장관상
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert5}
                alt="iconCert2"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>이러닝-에듀테크</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              비즈 모델 최우수상
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert4}
                alt="iconCert3"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>에듀테크 우수기업</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              콘텐츠·서비스 분야
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert3}
                alt="iconCert4"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>TTA</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>GS 1등급</Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert1}
                alt="iconCert5"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>조달청 </Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              벤처혁신상품
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          width: '70%',
          maxWidth: '80rem',
          marginTop: '10rem',
          marginBottom: '3rem',
          display: 'flex',
        }}
      >
        <Box style={{flex: '4'}}>
          <Box style={{color: '#00C500', fontWeight: '700'}}>AI 피드백</Box>
          <Box
            style={{
              fontSize: '1.75rem',
              fontWeight: '700',
              marginTop: '0.5rem',
              color: '#49545c',
            }}
          >
            AI 피드백을 반영해서 완성하는 글
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d', marginTop: '1rem'}}>
            AI 피드백을 받으면서 즉각적으로 글을 수정할 수 있습니다. 맞춤법,
            띄어쓰기, 문장 호응, 다양한 문장 구성 등 글의 기본 형식을 갖추는 데
            도움을 줍니다.
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d'}}>
            선생님께 제출한 글에 대해서는 최종 리포트(웹 화면, pdf파일)를 받을
            수 있습니다.
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d'}}>
            <span style={{fontWeight: 'bold'}}>AI 글쓰기 연습</span>을
            활용하세요. 키위 AI 피드백과 함께 스스로 글을 완성해 나가는 힘을
            키워 보세요.
          </Box>
        </Box>
        <Box
          style={{
            flex: '6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={imgMain2}
            alt="imgMain2"
            style={{width: '80%', maxWidth: '25rem'}}
          />
        </Box>
      </Box>
      <Box
        style={{
          width: '70%',
          maxWidth: '80rem',
          marginTop: '7rem',
          marginBottom: '5rem',
          display: 'flex',
        }}
      >
        <Box style={{flex: '4'}}>
          <Box style={{color: '#00C500', fontWeight: '700'}}>
            선생님별 과제 관리
          </Box>
          <Box
            style={{
              fontSize: '1.75rem',
              fontWeight: '700',
              marginTop: '0.5rem',
              color: '#49545c',
            }}
          >
            선생님마다 강의실이 만들어져요!
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d', marginTop: '1rem'}}>
            선생님께 인증 요청을 보내세요. 인증을 받으면 ‘나의 강의실’과 ‘AI
            글쓰기 연습’ 기능이 활성화됩니다. 선생님께 과제를 받기 위해서는
            반드시 선생님 연결이 필요합니다.
          </Box>

          <Button
            style={{
              display: 'block',
              marginTop: '1rem',
              padding: '0.3rem 1rem',
              fontSize: '1rem',
              color: '#49545c',
              border: '1px solid #D8DCDE',
            }}
            onClick={() => {
              setExpand1(prev => !prev);
            }}
          >
            Q. 키위런을 개인이 사용할 수 있나요?
          </Button>
          <Box
            style={{
              display: expand1 ? 'block' : 'none',
              margin: '1rem',
              color: '#68737D',
            }}
          >
            네, 사용할 수 있습니다. 키위런은 키위티 선생님과 연결되어야만 사용할
            수 있습니다. 연결된 모든 선생님과 연결이 끊어지면 키위런 사용자도
            서비스를 이용할 수 없습니다. 그러나, 글쓰기 대회 주최자 선생님 등
            기본 제공되는 선생님과 연결되면 개인적인 기능을 이용할 수 있습니다.
          </Box>
          <Button
            style={{
              display: 'block',
              marginTop: '1rem',
              padding: '0.3rem 1rem',
              fontSize: '1rem',
              color: '#49545c',
              border: '1px solid #D8DCDE',
            }}
            onClick={() => {
              setExpand2(prev => !prev);
            }}
          >
            Q. AI 글쓰기 연습은 어떻게 활용하나요?
          </Button>
          <Box
            style={{
              display: expand2 ? 'block' : 'none',
              margin: '1rem',
              color: '#68737D',
            }}
          >
            ‘AI 글쓰기 연습’은 스스로 글쓰기 연습할 때 사용합니다. 스스로
            관리하고 선생님에게 제출되지 않습니다. 글의 종류를 선택하고, 제목과
            본문을 입력하세요. AI 피드백을 받을 수 있습니다. 글쓰기를 완성한 후,
            키위챗과도 대화해 보세요. AI 글쓰기 연습은 [연습한 글]에 저장됩니다.
          </Box>
        </Box>
        <Box
          style={{
            flex: '6',
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'end',
          }}
        >
          <img src={imgMain3} alt="imgMain3" style={{width: '90%'}} />
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};
export default MainPage;
