import React, {useEffect} from 'react';
import APIUtils from './APIUtils';
const checkVersionUpdate = async () => {
  const package_version = process.env.REACT_APP_VERSION;

  const versionCheck = async () => {
    try {
      const response = await APIUtils.VesionCheck();
      return response;
    } catch (err) {
      console.log(err);
    }
  };
  versionCheck().then(r => {
    const version = r.data.version;
    if (version && version.trim() !== package_version.trim()) {
      if (localStorage.getItem('reloadCounter') == 0) {
        localStorage['reloadCounter']++;
        window.location.reload();
      }
    } else if (version.trim() == package_version.trim()) {
      localStorage['reloadCounter'] = 0;
    } else {
      console.error('Failed to fetch version:', r.status);
    }
  });
};
async function CheckVersion() {
  useEffect(() => {
    checkVersionUpdate();

    const handleFocusChange = () => {
      checkVersionUpdate();
    };
    window.addEventListener('focus', handleFocusChange);
    return () => {
      window.removeEventListener('focus', handleFocusChange);
    };
  }, []);
}

export default CheckVersion;
