import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import FooterView from 'view/common/Footer';
import MobileFooterView from 'view/common/MobileFooter';
import APIUtils from 'common/utils/APIUtils';
import AlertIcon from 'asset/imageV2/icon_alert1.png';
import {isMobileOnly} from 'react-device-detect';

const PatchPage = () => {
  const [annoucnement, setAnnouncement] = useState(
    '[점검 시간] 2024년 07월 16일\n 18:00~20:00(약 2시간)',
  );
  const checkAnnouncement = async () => {
    const announcementCheck = async () => {
      try {
        const response = await APIUtils.CheckAnnouncement();
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    return await announcementCheck().then(r => {
      if (r.data.downtime_announce != null) {
        setAnnouncement(r.data.downtime_announce);
      }
    });
  };
  useEffect(() => {
    checkAnnouncement();
  }, []);
  function showFooter() {
    if (isMobileOnly) {
      return <MobileFooterView />;
    }
    return <FooterView />;
  }
  return (
    <Box
      style={{
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box style={{flex: 1}} />
      <Box
        style={{
          minHeight: isMobileOnly ? '10rem' : '30rem',
          padding: '2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <img
          alt="AlertIcon"
          src={AlertIcon}
          height={isMobileOnly ? '100rem' : '200rem'}
          width={isMobileOnly ? '100rem' : '200rem'}
        />
        <Box
          style={{
            fontWeight: 'bold',
            fontSize: isMobileOnly ? '1.5rem' : '3rem',
            marginTop: '2rem',
          }}
        >
          {'서비스 점검 중입니다.'}
        </Box>
        <Box
          style={{
            fontSize: isMobileOnly ? '0.8rem' : '1.5rem',
            marginTop: '2rem',
          }}
        >
          {'안정적인 서비스 이용을 위해 서버 점검 중 입니다.'}
        </Box>
        <Box style={{fontSize: isMobileOnly ? '0.8rem' : '1.5rem'}}>
          {'이용에 불편을 드려 죄송합니다.'}
        </Box>
        <Box
          style={{
            fontWeight: 'bold',
            marginTop: '2rem',
            color: '#ff9900',
            fontSize: isMobileOnly ? '1rem' : '2rem',
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
          }}
        >
          {annoucnement}
        </Box>
      </Box>
      <Box style={{flex: 1}} />
      {showFooter()}
    </Box>
  );
};
export default PatchPage;
