import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';

const ItemView = props => {
  return (
    <Box style={{display: 'flex', justifyContent: 'end'}}>
      <Box
        style={{
          width: 'fit-content',
          margin: '0.7rem 1.5rem 0.7rem 3rem',
          padding: '0.875rem',
          backgroundColor: '#BCEB80',
          fontSize: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '4px 5px 13px 5px #dadedf',
        }}
      >
        {props.content}
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  content: PropTypes.string,
};
export default ItemView;
