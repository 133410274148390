import {createSlice} from '@reduxjs/toolkit';
const initialState = {
  isLogin: false,
  user_type: -1,
  student_id: -1,
  student_name: '',
  student_email: '',
  path: '',
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveLogin: (state, action) => {
      state.isLogin = true;
      state.user_type = action.payload.user_type;
      state.student_id = action.payload.student_id;
      state.student_name = action.payload.student_name;
      state.student_email = action.payload.student_email;
      state.path = action.payload.path;
    },
    modifyAccountInfo: (state, action) => {
      state.student_name = action.payload.student_name;
    },
    removeLogin: (state, action) => {
      state.isLogin = false;
      state.user_type = -1;
      state.student_id = -1;
      state.student_name = '';
      state.student_email = '';
      state.path = '';
    },
  },
});
export const {saveLogin, removeLogin, modifyAccountInfo} = authSlice.actions;
export default authSlice.reducer;
