export const mypageWholePage = {
  minHeight: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'start',
  width: '100%',
};
export const mypageBox1 = {
  paddingTop: '2rem',
  paddingBottom: '2rem',
  width: '90%',
  paddingRight: '5%',
  paddingLeft: '5%',
  backgroundColor: '#F1FCF0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
};
export const mypageBox2 = {
  paddingTop: '3rem',
  paddingBottom: '3rem',
  width: '90%',
  paddingRight: '5%',
  paddingLeft: '5%',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
};
