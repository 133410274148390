import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import ApiLoading from 'view/common/ApiLoading';
import HeaderView from 'view/common/Header';
import FooterView from 'view/common/Footer';
import APIUtils from 'common/utils/APIUtils';
import ErrorIcon from 'asset/imageV2/icon_error1.png';
import {isMobileOnly} from 'react-device-detect';

const RedirectPage = () => {
  const parameter = useParams();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const isLogin = authReducer.isLogin;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    console.log('wtf');
    if (!isLogin) {
      navigate('/login');
    }
    if (isNaN(Number(parameter.key))) {
      navigate('/');
    }
    getTaskList(Number(parameter.key));
  }, []);

  function getTaskList(searchTaskID) {
    const getTask = async () => {
      try {
        const response = await APIUtils.TaskOngoing(authReducer.student_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getTask().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        const runningTasks = res.data.task_list.map(item => item.task_id);
        if (runningTasks.includes(searchTaskID)) {
          if (isMobileOnly) {
            navigate('/writing', {
              state: {
                task_id: searchTaskID,
              },
            });
          } else {
            navigate('/writing2', {
              state: {
                task_id: searchTaskID,
              },
            });
          }
        }
        setLoading(false);
      }
    });
  }

  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HeaderView />
      <Box display={{flex: 1}}></Box>
      <Box
        style={{
          minHeight: '30rem',
          padding: '2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '1rem',
          fontSize: '1.5rem',
        }}
      >
        <img
          alt="ErrorIcon"
          src={ErrorIcon}
          height={'200rem'}
          width={'200rem'}
        />
        <Box style={{fontWeight: 'bold'}}>
          {'해당 과제가 진행중이지 않거나'}
        </Box>
        <Box style={{fontWeight: 'bold'}}>
          {authReducer.student_name +
            '님이 해당 과제에 포함되어 있지 않습니다.'}
        </Box>
        <Box style={{fontWeight: 'bold'}}>{'담당 선생님께 문의하세요.'}</Box>
      </Box>
      <Box display={{flex: 1}}></Box>
      <FooterView />
    </Box>
  );
};
export default RedirectPage;
