import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import {StringUtils} from 'common/utils/StringUtils';
import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {useSelector} from 'react-redux';

export const TaskView = props => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const dateLeft = StringUtils.getDateDiff(props.task.task_end_time);

  function showLabel() {
    return (
      <Box style={{display: 'flex', gap: '0.5rem'}}>
        <Box
          style={{
            color: 'white',
            backgroundColor: '#68737D',
            width: 'fit-content',
            padding: '0.125rem 0.375rem',
            borderRadius: '0.3125rem',
            fontSize: '0.875rem',
            fontWeight: 'bolder',
          }}
        >
          D-{dateLeft}
        </Box>
        <Box
          style={{
            color: '#FF9900',
            backgroundColor: 'white',
            width: 'fit-content',
            padding: '0.125rem 0.375rem',
            borderRadius: '0.3125rem',
            fontSize: '0.875rem',
            fontWeight: 'bolder',
            border: '1px solid #D8DCDE',
          }}
        >
          진행중
        </Box>
        <Box
          style={{
            display: props.task.task_type == 1 ? '' : 'none',
            color: '#279EFF',
            backgroundColor: 'white',
            width: 'fit-content',
            padding: '0.125rem 0.375rem',
            borderRadius: '0.3125rem',
            fontSize: '0.875rem',
            fontWeight: 'bolder',
            border: '1px solid #279EFF',
          }}
        >
          {'AI 피드백'}
        </Box>
      </Box>
    );
  }

  function showButton() {
    if (props.task.task_submit) {
      return (
        <Button
          style={{
            color: '#00C500',
            fontSize: '0.875rem',
            border: '1px solid #00C500',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '7rem',
          }}
          onClick={() => {
            APIUtils.LoggerLog(
              authReducer.user_id,
              'keewil',
              'info',
              window.location.pathname,
              'navigate_writing2',
            );
            navigate('/writing2', {
              state: {
                task_id: props.task.task_id,
              },
            });
          }}
        >
          수정하기
        </Button>
      );
    }
    return (
      <Button
        style={{
          color: '#FF9900',
          fontSize: '0.875rem',
          border: '1px solid #FF9900',
          borderRadius: '1.625rem',
          padding: '0.7rem 0rem',
          width: '7rem',
        }}
        onClick={() => {
          APIUtils.LoggerLog(
            authReducer.user_id,
            'keewil',
            'info',
            window.location.pathname,
            'navigate_writing2',
          );
          navigate('/writing2', {
            state: {
              task_id: props.task.task_id,
            },
          });
        }}
      >
        작성하기
      </Button>
    );
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        paddingBottom: '1.7rem',
        borderBottom: '2px solid #A8B1B7',
      }}
    >
      <Box style={{display: 'flex', flexDirection: 'column', gap: '0.75rem'}}>
        <Box style={{display: 'flex', gap: '0.5rem'}}>{showLabel()}</Box>
        <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>
          {'[' + props.task.task_janre + '] ' + props.task.task_name}
        </Box>
        <Box style={{color: '#636C73'}}>
          {props.task.teacher_name + ' 선생님'}
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        {showButton()}
      </Box>
    </Box>
  );
};
TaskView.propTypes = {
  task: PropTypes.object,
  detailEvent: PropTypes.func,
};
export default TaskView;
