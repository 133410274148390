import React, {useEffect} from 'react';
import axios from 'axios';
import {Button} from '@mui/material';
import PropTypes from 'prop-types';
import {loginBox} from 'view/login/MobileStyles';

const CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
const REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT_URI;

const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`; //&prompt=login

const KakaoButton = props => {
  const kakaoLogin = async () => {
    await axios.get(KAKAO_AUTH_URL).catch(err => {
      console.log(err);
    });
  };
  const userAccessToken = () => {
    window.location.href.includes('code') && getToken();
  };

  const getToken = () => {
    const code = window.location.href.split('=')[1];

    const TOKEN_URL = `https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&code=${code}`;
    axios
      .post(TOKEN_URL, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      })
      .then(res => {
        props.onSuccess(res.data.access_token);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    userAccessToken();
  }, []);
  return (
    <Button
      style={loginBox.coloredBox('#ffe923')}
      href={KAKAO_AUTH_URL}
      onClick={() => {
        kakaoLogin();
      }}
    >
      카카오톡으로 로그인/가입하기
    </Button>
  );
};
KakaoButton.propTypes = {
  onSuccess: PropTypes.func,
};
export default KakaoButton;
