import {Box, Button, tabsClasses} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import APIUtils from 'common/utils/APIUtils';
import TeacherView2 from 'view/mypage/TeacherView2';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import ApiLoading from 'view/common/ApiLoading2';

export const FragmentView = props => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);

  const [teacherList, setTeacherList] = useState([]);
  const [loading, setLoading] = useState(true);

  function getTeacherList() {
    const getTeacher = async () => {
      try {
        const response = await APIUtils.TaskTeacherList(authReducer.student_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          setTeacherList(
            response.data.teacher_list.sort((a, b) => {
              if (a.teacher_name > b.teacher_name) {
                return 1;
              }
              if (a.teacher_name < b.teacher_name) {
                return -1;
              }
              return 0;
            }),
          );
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getTeacher();
  }
  useEffect(() => {
    getTeacherList();
  }, []);
  if (loading) {
    return <ApiLoading />;
  }
  return (
    <Box>
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>나의 강의실</Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '1.75rem',
        }}
      />
      <Box style={{color: '#87929D', fontSize: '0.875rem'}}>
        인증 수락을 받은 선생님별로 강의실이 열립니다. 각 강의실을 열어 과제를
        확인하세요.
      </Box>
      <Box style={{marginTop: '2rem'}}>
        {teacherList.map((val, idx) => {
          return (
            <TeacherView2
              key={'teacherVeiw' + idx}
              teacher_info={val}
              selectEvent={props.classSelect}
            />
          );
        })}
      </Box>
    </Box>
  );
};
FragmentView.propTypes = {
  classSelect: PropTypes.func,
};
export default FragmentView;
