import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {removeLogin} from 'common/reducer/auth';
import {StringUtils} from 'common/utils/StringUtils';
import {useCookies} from 'react-cookie';

function LoginCookie() {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['lastTime']);
  useEffect(() => {
    window.addEventListener('beforeunload', ev => {
      setCookie('lastTime', new Date());
    });
    if (cookies.lastTime == undefined) {
      dispatch(removeLogin());
    } else {
      const hours = StringUtils.getHourDiff(cookies.lastTime);
      if (hours > 72) {
        dispatch(removeLogin());
      }
    }
  }, []);
}

export default LoginCookie;
