import React from 'react';
import styled, {keyframes} from 'styled-components';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';

const animation1 = keyframes`
0% { opacity: 0; }
50% { opacity: 1; }
100% { opacity: 0; }
`;

const StyledWrapper1 = styled.div`
  animation: ${animation1} 3s 0s infinite;
  background-color: #bceb80;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
`;

const StyledWrapper2 = styled.div`
  animation: ${animation1} 3s 0.5s infinite;
  background-color: #bceb80;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
`;
const StyledWrapper3 = styled.div`
  animation: ${animation1} 3s 1s infinite;
  background-color: #bceb80;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
`;
const AnimationBox = ({children, ...rest}) => {
  return (
    <Box
      style={{
        display: 'flex',
        fontSize: '2rem',
        padding: '1rem',
        gap: '1rem',
      }}
    >
      <StyledWrapper1 {...rest}></StyledWrapper1>
      <StyledWrapper2 {...rest}></StyledWrapper2>
      <StyledWrapper3 {...rest}></StyledWrapper3>
    </Box>
  );
};
AnimationBox.propTypes = {
  children: PropTypes.node,
};
export default AnimationBox;
