import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import {StringUtils} from 'common/utils/StringUtils';
import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import next1 from 'asset/imageV2/icon_mobile_next1.png';
import next2 from 'asset/imageV2/icon_mobile_next2.png';

export const TaskView = props => {
  const navigate = useNavigate();
  const dateLeft = StringUtils.getDateDiff(props.task.task_end_time);

  function showLabel() {
    if (props.task.task_status == '진행중') {
      return (
        <Box
          style={{
            display: 'flex',
            gap: '0.5rem',
          }}
        >
          <Box
            style={{
              color: 'white',
              backgroundColor: '#ff9900',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.775rem',
              fontWeight: 'bolder',
            }}
          >
            D-{dateLeft}
          </Box>

          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.775rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
        </Box>
      );
    } else if (props.task.task_status == '대기') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#68737D',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.775rem',
              fontWeight: 'bolder',
              border: '1px solid #68737D',
            }}
          >
            대기
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.775rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
        </Box>
      );
    } else if (props.task.task_status == '종료') {
      return (
        <Box
          style={{
            color: '#87929D',
            backgroundColor: '#E9EBED',
            width: 'fit-content',
            padding: '0.125rem 0.375rem',
            borderRadius: '0.3125rem',
            fontSize: '0.775rem',
            fontWeight: 'bolder',
          }}
        >
          종료
        </Box>
      );
    }
  }

  function showButton() {
    if (props.task.task_status == '진행중') {
      if (props.task.task_submit) {
        return (
          <Button
            style={{
              color: '#00C500',
              fontSize: '0.875rem',
              borderRadius: '1.625rem',
              display: 'flex',
              flexDirection: 'column',
            }}
            onClick={() => {
              navigate('/writing', {
                state: {
                  task_id: props.task.task_id,
                },
              });
            }}
          >
            <img src={next1} alt={'next1'} style={{width: '2rem'}} />
            수정
          </Button>
        );
      }
      return (
        <Button
          style={{
            color: '#FF9900',
            fontSize: '0.875rem',
            borderRadius: '1.625rem',
            display: 'flex',
            flexDirection: 'column',
          }}
          onClick={() => {
            navigate('/writing', {
              state: {
                task_id: props.task.task_id,
              },
            });
          }}
        >
          <img src={next2} alt={'next2'} style={{width: '2rem'}} />
          작성
        </Button>
      );
    }
  }
  return (
    <Box
      style={{
        display: 'flex',
        marginTop: '1rem',
        padding: '0.9rem 1rem',
        borderBottom: '0.5px solid #CBC8C8',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.75rem',
          flex: 1,
        }}
      >
        <Box style={{display: 'flex', gap: '0.5rem'}}>{showLabel()}</Box>
        <Box style={{fontSize: '1.125rem', fontWeight: '500'}}>
          {'[' + props.task.task_janre + '] ' + props.task.task_name}
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        {showButton()}
      </Box>
    </Box>
  );
};
TaskView.propTypes = {
  task: PropTypes.object,
  detailEvent: PropTypes.func,
};
export default TaskView;
