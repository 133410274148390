import React from 'react';
import {GoogleOAuthProvider} from '@react-oauth/google';
import PropTypes from 'prop-types';
import CustomGoogleButton from './MobileCustomGoogleButton';
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GoogleButton = props => {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <CustomGoogleButton onSuccess={props.onSuccess} />
    </GoogleOAuthProvider>
  );
};

GoogleButton.propTypes = {
  onSuccess: PropTypes.func,
};
export default GoogleButton;
