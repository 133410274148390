import React, {useState} from 'react';
import {Box} from '@mui/material';
import HeaderView from 'view/common/MobileHeader';
import {mypageWholePage} from 'view/mypage/MobileStyles';
import FooterView from 'view/common/MobileFooter';
import FragmentClass from 'view/mypage/MobileFragmentClass';
import FragmentClassSelected from 'view/mypage/MobileFragmentClassSelected';

const MyPage = () => {
  const [fragmentStatus, setFragmentStatus] = useState(1);
  const [selectedUserID, setSelectedUserID] = useState(0);
  const [selectedTeacherName, setSelectedTeacherName] = useState('');

  function showFragment() {
    switch (fragmentStatus) {
      case 1:
        return <FragmentClass classSelect={classSelect} />;
      case 2:
        return (
          <FragmentClassSelected
            user_id={selectedUserID}
            teacher_name={selectedTeacherName}
            backEvent={() => {
              setFragmentStatus(1);
              setSelectedUserID(0);
              setSelectedTeacherName('');
            }}
          />
        );

      default:
        return <FragmentClass />;
    }
  }
  function classSelect(user_id, name) {
    setFragmentStatus(2);
    setSelectedUserID(user_id);
    setSelectedTeacherName(name);
  }

  return (
    <Box style={mypageWholePage}>
      <HeaderView />
      {showFragment()}
      <FooterView />
    </Box>
  );
};

export default MyPage;
