import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React, {useState} from 'react';

export const TeacherView = props => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '1rem 1rem 0rem 1rem',
        padding: '1.2rem 0.5rem',
        borderBottom: '0.5px solid #CBC8C8',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
      }}
      onClick={() =>
        props.selectEvent(
          props.teacher_info.user_id,
          props.teacher_info.teacher_name,
        )
      }
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            visibility: props.teacher_info.new_task ? 'visible' : 'hidden',
            backgroundColor: '#00C500',
            width: '1rem',
            height: '1rem',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            borderRadius: '1.5rem',
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textAlign: 'center',
            marginLeft: '0.5rem',
          }}
        >
          N
        </Box>
        <Box
          style={{
            marginLeft: '0.5rem',
            fontSize: '1.125rem',
            fontWeight: 'bold',
          }}
        >
          {props.teacher_info.teacher_name + ' 선생님'}
        </Box>
      </Box>
    </Box>
  );
};
TeacherView.propTypes = {
  teacher_info: PropTypes.object,
  selectEvent: PropTypes.func,
};
export default TeacherView;
