import CryptoJS from 'crypto-js';
const aes256key = process.env.REACT_APP_AES_256_KEY;
const aes256Iv = process.env.REACT_APP_AES_256_IV;
const AESUtils = {
  encodeByAES56(data) {
    const cipher = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      CryptoJS.enc.Utf8.parse(aes256key),
      {
        iv: CryptoJS.enc.Utf8.parse(aes256Iv), // [Enter IV (Optional) 지정 방식]
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC, // [cbc 모드 선택]
      },
    );
    return cipher.toString();
  },
  decodeByAES56(data) {
    const cipher = CryptoJS.AES.decrypt(
      data,
      CryptoJS.enc.Utf8.parse(aes256key),
      {
        iv: CryptoJS.enc.Utf8.parse(aes256Iv), // [Enter IV (Optional) 지정 방식]
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC, // [cbc 모드 선택]
      },
    );
    const ret_val = {data: JSON.parse(cipher.toString(CryptoJS.enc.Utf8))};
    return ret_val;
  },
};
export default AESUtils;
