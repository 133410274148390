import React from 'react';
import {useGoogleLogin} from '@react-oauth/google';
import {Button} from '@mui/material';
import PropTypes from 'prop-types';
import {loginBox} from 'view/login/MobileStyles';
import axios from 'axios';

const GoogleButton = props => {
  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      props.onSuccess(tokenResponse.access_token);
    },
  });
  return (
    <Button style={loginBox.coloredBox('#f9a4a4')} onClick={login}>
      구글로 로그인/가입하기
    </Button>
  );
};

GoogleButton.propTypes = {
  onSuccess: PropTypes.func,
};
export default GoogleButton;
