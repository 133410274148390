export const NumberUtils = {
  getAnalysisNumber: function (num = 0) {
    const temp1 = num * 10;
    const temp2 = Math.round(temp1);
    return temp2 / 10;
  },
  getThousandComma: function (num = 0) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
};
