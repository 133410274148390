import React, {useEffect, useState} from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';
import sampleText from 'asset/json/sample_text.json';
import {useNavigate, useLocation} from 'react-router-dom';
import {AnalysisText} from 'view/result/AnalysisText';
import {
  TitleComponent2,
  BookComponent,
  CenteredButton,
  SingleLineComponent,
  CustomTextField2,
  CustomTextField3,
} from 'view/common/Components';
import HeaderView from 'view/common/Header';
import {useSelector} from 'react-redux';
import FooterView from 'view/common/Footer';

import emojiRegex from 'emoji-regex';
import {StringUtils} from 'common/utils/StringUtils';
import APIUtils from 'common/utils/APIUtils';
import NoticketPopup from 'view/popup/NoTicket2';
import SubmitDonePopup from 'view/popup/SubmitDonePopup';
import SubmitInvalidPopup from 'view/popup/SubmitInvalidPopup';
import ApiLoading from 'view/common/ApiLoading';
import ChatOverlay from 'view/chat/ChatOveray';

const WritingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authReducer = useSelector(state => state.authReducer);

  const [loading, setLoading] = useState(false);

  const [sample, setSample] = useState({});
  const [selectValue, setSelectValue] = useState('');
  const [selectOption, setSelectOption] = useState([]);
  const [bodyText, setBodyText] = useState('');
  const [titleText, setTitleText] = useState('');

  const [taskID, setTaskID] = useState(0);
  const [taskDateLeft, setTaskDateLeft] = useState(0);
  const [taskDateText, setTaskDateText] = useState('');
  const [taskTitleText, setTaskTitleText] = useState('');
  const [taskMessage, setTaskMessage] = useState('');
  const [taskJanre, setTaskJanre] = useState('');
  const [taskType, setTaskType] = useState(1);

  const [textCountChecked, setTextCountChecked] = useState(false);
  const [textCountMin, setTextCountMin] = useState('');
  const [textCountMax, setTextCountMax] = useState('');

  const [requiredWordChecked, setRequiredWordChecked] = useState(false);
  const [requiredWord, setRequiredWord] = useState('');
  const [unfoundRequiredWord, setUnfoundRequiredWord] = useState('');

  const [chatVisibility, setChatVisibility] = useState(false);
  const [chatTask, setChatTask] = useState(undefined);

  const [noticketVisibility, setNoticketVisibility] = useState(false);

  function noticketComplete() {
    APIUtils.LoggerLog(
      authReducer.user_id,
      'keewil',
      'info',
      window.location.pathname,
      'navigate_home',
    );
    setNoticketVisibility(false);
    navigate('/');
  }
  const [submitInvalidVisibility, setSubmitInvalidVisibility] = useState(false);
  function submitInvalidCancel() {
    setSubmitInvalidVisibility(false);
  }

  const [submitDoneVisibility, setSubmitDoneVisibility] = useState(false);
  function submitDoneConfirm() {
    APIUtils.LoggerLog(
      authReducer.user_id,
      'keewil',
      'info',
      window.location.pathname,
      'navigate_mypage',
    );
    setSubmitDoneVisibility(false);
    navigate('/mypage');
  }

  const handleSelect = event => {
    const dictKey = selectOption[event.target.value].text;

    setSelectValue(event.target.value);
    setBodyText(sample[dictKey]);
    setTitleText(dictKey);
  };
  const handleBody = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 10000) {
        inputVal = inputVal.substring(0, 10000);
      }
      setBodyText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const handleTitle = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 50) {
        inputVal = inputVal.substring(0, 50);
      }
      setTitleText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };

  function navigateToResult(disabled) {
    if (disabled) {
      alert('학생 이름, 제목, 본문을 모두 입력하셔야 제출하기가 가능합니다.');
      return;
    }

    const trimmedText = StringUtils.getTrimmedBody(bodyText);
    if (taskType == 2) {
      if (
        textCountChecked &&
        (bodyText.replace(/\s+/g, ' ').length < textCountMin ||
          bodyText.replace(/\s+/g, ' ').length > textCountMax)
      ) {
        setSubmitInvalidVisibility(true);
        return;
      }
      if (
        requiredWordChecked &&
        0 != unfoundRequiredWord.split(',').filter(val => val != '').length
      ) {
        setSubmitInvalidVisibility(true);
        return;
      }
      analyzeEvent(titleText, taskJanre, trimmedText, taskID);
    } else {
      window.localStorage.removeItem('title');
      window.localStorage.removeItem('text');
      window.localStorage.removeItem('janre');
      APIUtils.LoggerLog(
        authReducer.user_id,
        'keewil',
        'info',
        window.location.pathname,
        'navigate_rewrite2',
      );
      navigate('/rewrite2', {
        state: {
          title: titleText,
          text: trimmedText,
          writing_janre: taskJanre,
          task_id: taskID,
        },
      });
    }
  }
  function analyzeEvent(new_title, new_janre, new_text, new_task_id) {
    setLoading(true);
    const fetAnalasisResult = async (
      inputText,
      inputTitle,
      inputJanre,
      inputTaskID,
    ) => {
      try {
        const response = await APIUtils.TaskEval(
          authReducer.student_id,
          inputTaskID,
          inputTitle,
          inputJanre,
          inputText,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const saveResult = async result => {
      try {
        const response = await APIUtils.TaskEvalSave(
          authReducer.student_id,
          new_task_id,
          result,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const submitResult = async () => {
      try {
        const response = await APIUtils.TaskSubmit(
          authReducer.student_id,
          new_task_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    fetAnalasisResult(new_text, new_title, new_janre, new_task_id)
      .then(res => {
        if (res.data.ret_code == 1000) {
          const jsonResult = AnalysisText.createSavingJsonFromEvalResult(
            res.data.eval_result,
            new_title,
            new_text,
            '성인',
            authReducer.student_name,
          );
          return saveResult(jsonResult);
        }
      })
      .then(re => {
        if (re.status == 200 && re.data.ret_code == 1000) {
          return submitResult();
        }
      })
      .then(r => {
        if (r.status == 200 && r.data.ret_code == 1000) {
          setSubmitDoneVisibility(true);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }

  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.AccountPassCheck(
          authReducer.student_id,
        );
        if (response.status == 200 && response.data.ret_code == 1000) {
          if (!response.data.pass_available) {
            setNoticketVisibility(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }
  function getTaskInfo(task_id) {
    const taskResult = async () => {
      try {
        const response = await APIUtils.TaskCall(
          authReducer.student_id,
          task_id,
        );
        if (response.status == 200 && response.data.ret_code == 1000) {
          setChatTask(response.data);
          const dateLeft = StringUtils.getDateDiff(response.data.task_end_time);
          setTaskDateLeft(dateLeft);
          setTaskDateText(
            StringUtils.getDateStringKr(response.data.task_start_time) +
              '~' +
              StringUtils.getDateStringKr(response.data.task_end_time),
          );
          setTaskTitleText(
            '[' + response.data.task_janre + '] ' + response.data.task_name,
          );
          setTaskMessage(response.data.task_message);
          setTaskJanre(response.data.task_janre);
          setTitleText(response.data.title);
          setBodyText(response.data.text);
          setTaskType(response.data.task_type);

          if (
            response.data.title == '' &&
            response.data.text == '' &&
            localStorage.getItem('taskTitle' + task_id) &&
            localStorage.getItem('taskBody' + task_id)
          ) {
            setTitleText(localStorage.getItem('taskTitle' + task_id));
            setBodyText(localStorage.getItem('taskBody' + task_id));
          }

          setTextCountChecked(response.data.length_check == 1);
          setTextCountMin(response.data.min_length);
          setTextCountMax(response.data.max_length);
          setRequiredWordChecked(response.data.keyword_check == 1);
          setRequiredWord(response.data.keyword);
        }
      } catch (err) {
        console.log(err);
      }
    };
    taskResult();
  }
  function createSampleSelector() {
    if (process.env.REACT_APP_ENV == 'live') {
      return <></>;
    } else {
      return (
        <FormControl
          size="small"
          sx={{
            m: 1,
            minWidth: 200,
            color: Palette.white,
          }}
        >
          <InputLabel
            id="sample-select-label"
            style={{
              color: 'blue',
            }}
          >
            예시 글 가져오기
          </InputLabel>
          <Select
            labelId="sample-select-label"
            id="sample-select"
            onChange={handleSelect}
            value={selectValue}
            label="예시 글 가져오기"
            style={{
              color: Palette.white,
              backgroundColor: Palette.grey1,
            }}
          >
            {selectOption.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  }
  useEffect(() => {
    let input_task_id = 0;
    if (
      location.state == null &&
      window.localStorage.getItem('task_id') == null
    ) {
      navigate('/', {replace: true});
      return;
    } else if (location.state == null) {
      input_task_id = Number(window.localStorage.getItem('task_id'));
    } else {
      input_task_id = location.state.task_id;
    }

    let dict = {};
    let selectList = [];
    sampleText.forEach((item, index) => {
      dict[item.subject] = item.text;
      selectList.push({value: index, text: item.subject});
    });
    setTaskID(input_task_id);
    setSample(dict);
    setSelectOption(selectList);
    getPassInfo();
    getTaskInfo(input_task_id);
  }, []);
  useEffect(() => {
    if (titleText == '' && bodyText == '') {
      return;
    }
    localStorage.setItem('taskBody' + taskID, bodyText);
    localStorage.setItem('taskTitle' + taskID, titleText);
  }, [taskID, titleText, bodyText]);
  useEffect(() => {
    setUnfoundRequiredWord(
      requiredWord
        .split(',')
        .filter(val => !bodyText.includes(val))
        .join(','),
    );
  }, [bodyText, requiredWord]);
  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box style={{width: chatVisibility ? '70%' : '100%'}}>
      <NoticketPopup
        visibility={noticketVisibility}
        completeEvent={noticketComplete}
      />
      <SubmitDonePopup
        visibility={submitDoneVisibility}
        completeEvent={submitDoneConfirm}
      />
      <SubmitInvalidPopup
        visibility={submitInvalidVisibility}
        textCountChecked={textCountChecked}
        textCountMin={Number(textCountMin)}
        textCountMax={Number(textCountMax)}
        currentCount={bodyText.replace(/\s+/g, ' ').length}
        requiredWordChecked={requiredWordChecked}
        requiredWord={requiredWord}
        unfoundRequiredWord={unfoundRequiredWord}
        cancelEvent={submitInvalidCancel}
      />
      <HeaderView />
      <TitleComponent2
        dateLeft={taskDateLeft}
        dateText={taskDateText}
        titleText={taskTitleText}
        messageText={taskMessage}
        textCountChecked={textCountChecked}
        textCountMin={textCountMin}
        textCountMax={textCountMax}
        requiredWordChecked={requiredWordChecked}
        requiredWord={requiredWord}
      />
      <BookComponent shrink={chatVisibility}>
        <>
          {createSampleSelector()}
          <SingleLineComponent stepNumber={'01'} text="과제를 작성해 주세요." />
          <Box style={{marginTop: '1.5rem'}} />
          <CustomTextField2
            title="제목"
            emptyText={'글의 제목을 50자 내로 입력해 주세요.'}
            inputText={titleText}
            handleInput={handleTitle}
            maxCount="50"
          />
          <Box style={{marginTop: '1.5rem'}} />
          <CustomTextField3
            title="본문"
            emptyText={`평가할 글을 입력해주세요. 엔터 키[↲] 입력 시 문단이 나뉘었다고 인식합니다.\n문장 부호 외 특수문자/이모지가 포함된 경우, 평가 결과가 정상적으로 출력되지 않을 수 있습니다.`}
            inputText={bodyText}
            handleInput={handleBody}
            maxCount="10000"
          />
          <CenteredButton
            title={taskType == 2 ? '제출하기' : '평가 및 저장하기'}
            disabled={titleText == '' || bodyText.trim() == ''}
            clickEvent={navigateToResult}
          ></CenteredButton>

          <Box style={{display: taskType == 2 ? 'none' : ''}}>
            <Box
              style={{
                textAlign: 'center',
                marginTop: '2rem',
                color: '#87929D',
                fontWeight: 'bold',
              }}
            >
              <span
                style={{
                  color: '#00C500',
                }}
              >
                “평가 및 저장하기”
              </span>
              {' 버튼을 눌러도 선생님에게 제출되지 않습니다.'}
            </Box>
            <Box
              style={{
                textAlign: 'center',
                color: '#87929D',
                fontWeight: 'bold',
              }}
            >
              {'다음 화면에서 '}
              <span
                style={{
                  color: '#00C500',
                }}
              >
                “제출하기”
              </span>
              {' 버튼을 눌러야만 제출이 완료됩니다.'}
            </Box>
          </Box>
          <Box style={{display: taskType == 2 ? '' : 'none'}}>
            <Box
              style={{
                textAlign: 'center',
                marginTop: '2rem',
                color: '#87929D',
                fontWeight: 'bold',
              }}
            >
              <span
                style={{
                  color: '#00C500',
                }}
              >
                “제출하기”
              </span>
              {' 버튼을 누르면 선생님에게 제출됩니다.'}
            </Box>
          </Box>
        </>
      </BookComponent>
      <ChatOverlay
        chatVisibility={chatVisibility}
        handleVisibility={() => {
          setChatVisibility(!chatVisibility);
        }}
        taskID={taskID}
        chatTask={chatTask}
      />
      <FooterView />
    </Box>
  );
};
export default WritingPage;
