import React, {useEffect, useState} from 'react';
import {Box, Grid, Button, TextField} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import HeaderView from 'view/common/Header';
import APIUtils from 'common/utils/APIUtils';
import FooterView from 'view/common/Footer';
import SidebarView from 'view/mypage/SidebarView';
import FragmentHome from 'view/mypage/FragmentHome';
import FragmentAccount from 'view/mypage/FragmentAccount';
import FragmentClass from 'view/mypage/FragmentClass';
import FragmentClassSelected from 'view/mypage/FragmentClassSelected';
import FragmentSubmitTask from 'view/mypage/FragmentSubmitTask';
import FragmentPractice from 'view/mypage/FragmentPractice';

const MyPage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const navigate = useNavigate();
  const location = useLocation();

  const [fragmentStatus, setFragmentStatus] = useState(1);
  const [selectedUserID, setSelectedUserID] = useState(0);
  const [selectedTeacherName, setSelectedTeacherName] = useState('');
  function showFragment() {
    switch (fragmentStatus) {
      case 1:
        return <FragmentHome />;
      case 2:
        return <FragmentAccount />;
      case 3:
        return <FragmentClass classSelect={classSelect} />;
      case 31:
        return (
          <FragmentClassSelected
            user_id={selectedUserID}
            teacher_name={selectedTeacherName}
          />
        );
      case 4:
        return <FragmentSubmitTask user_id={selectedUserID} />;
      case 5:
        return <FragmentPractice user_id={selectedUserID} />;

      default:
        return <FragmentHome />;
    }
  }
  function classSelect(user_id, name) {
    setFragmentStatus(31);
    setSelectedUserID(user_id);
    setSelectedTeacherName(name);
  }
  function SidebarClickEvent(option) {
    setFragmentStatus(option);
  }
  useEffect(() => {
    let fragmentVal = location.state?.fragmentNum || 1;
    setFragmentStatus(fragmentVal);
  }, []);
  return (
    <Box
      style={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HeaderView />
      <Box
        style={{
          marginLeft: '10%',
          marginRight: '10%',
          marginBottom: '5%',
          marginTop: '8%',
          width: '80%',
          flex: 1,
          display: 'flex',
        }}
      >
        <Box>
          <SidebarView
            currentFragment={fragmentStatus}
            clickEvent={SidebarClickEvent}
          />
        </Box>
        <Box
          style={{
            flex: 1,
            marginLeft: '5%',
            marginRight: '5%',
          }}
        >
          {showFragment()}
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};

export default MyPage;
