import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import AuthTeacherPopup from 'view/popup/AuthTeacherPopup';
import AuthTeacherCompletePopup from 'view/popup/AuthTeacherCompletePopup';
import APIUtils from 'common/utils/APIUtils';

export const SidebarView = props => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const [completeVisibility, setCompleteVisibility] = useState(false);
  function completeCancel() {
    setCompleteVisibility(false);
  }

  const [authTeacherVisibility, setAuthTeacherVisibility] = useState(false);
  function authTeacherCancel() {
    setAuthTeacherVisibility(false);
  }
  function authTeacherConfirm() {
    setAuthTeacherVisibility(false);
    setCompleteVisibility(true);
  }
  return (
    <Box style={{display: 'flex', flexDirection: 'column', minWidth: '11rem'}}>
      <AuthTeacherCompletePopup
        visibility={completeVisibility}
        cancelEvent={completeCancel}
      />
      <AuthTeacherPopup
        visibility={authTeacherVisibility}
        cancelEvent={authTeacherCancel}
        confirmEvent={authTeacherConfirm}
      />
      <Box style={{fontWeight: 'bold', fontSize: '1.75rem', marginTop: '1rem'}}>
        {authReducer.student_name + ' 님'}
      </Box>
      <Box style={{color: '#68737d'}}>{authReducer.student_email}</Box>

      <Button
        style={{
          marginTop: '2.5rem',
          border: '1px solid black',
          color: '#49545c',
          fontWeight: 'bold',
        }}
        onClick={() => {
          setAuthTeacherVisibility(true);
        }}
      >
        선생님 연결하기
      </Button>
      <Button
        style={{
          marginTop: '0.5rem',
          color: 'white',
          backgroundColor: '#00C500',
          fontWeight: 'bold',
        }}
        onClick={() => {
          APIUtils.LoggerLog(
            authReducer.user_id,
            'keewil',
            'info',
            window.location.pathname,
            'navigate_writing1',
          );
          navigate('/writing1');
        }}
      >
        AI 글쓰기 연습
      </Button>
      <Box
        style={{
          marginTop: '3rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'start',
        }}
      >
        <Button
          style={{
            color: props.currentFragment == 1 ? '#00C500' : '#2f3941',
            backgroundColor:
              props.currentFragment == 1 ? 'rgba(0, 197, 0, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(1);
          }}
        >
          마이페이지
        </Button>
        <Button
          style={{
            color: props.currentFragment == 2 ? '#00C500' : '#2f3941',
            backgroundColor:
              props.currentFragment == 2 ? 'rgba(0, 197, 0, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(2);
          }}
        >
          계정 관리
        </Button>
        <Button
          style={{
            color: props.currentFragment == 3 ? '#00C500' : '#2f3941',
            backgroundColor:
              props.currentFragment == 3 ? 'rgba(0, 197, 0, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(3);
          }}
        >
          나의 강의실
        </Button>
        <Button
          style={{
            color: props.currentFragment == 4 ? '#00C500' : '#2f3941',
            backgroundColor:
              props.currentFragment == 4 ? 'rgba(0, 197, 0, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(4);
          }}
        >
          제출한 과제
        </Button>
        <Button
          style={{
            color: props.currentFragment == 5 ? '#00C500' : '#2f3941',
            backgroundColor:
              props.currentFragment == 5 ? 'rgba(0, 197, 0, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(5);
          }}
        >
          연습한 글
        </Button>
      </Box>
    </Box>
  );
};
SidebarView.propTypes = {
  currentFragment: PropTypes.number,
  clickEvent: PropTypes.func,
};

export default SidebarView;
