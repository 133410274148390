import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import iconArrowDown from 'asset/image/icon_arrow_down.png';
const PaginationItem = props => {
  const [visiblePage, setVisiblePage] = useState([]);
  useEffect(() => {
    const pages = [];
    const numPageOnce = 5;
    let i = 0;
    const maxPages = Math.min(props.totalPage, numPageOnce);
    const halfPages = Math.floor(maxPages / 2);
    let startPage = Math.max(1, props.currentPage - halfPages);
    if (startPage + maxPages > props.totalPage) {
      startPage = props.totalPage - maxPages + 1;
    }
    while (i < maxPages) {
      pages.push(startPage + i);
      i++;
    }
    setVisiblePage(pages);
  }, [props.totalPage, props.currentPage]);
  if (props.totalPage == 0) {
    return <></>;
  }
  const selectedColored = '#00C500';
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {/* <Box
        style={{
          marginLeft: '0.3rem',
          letterSpacing: '-10px',
          paddingRight: '10px',
          color: '#717171',
          cursor: props.currentPage == 1 ? 'default' : 'pointer',
          fontWeight: 'bold',
        }}
        onClick={() => {
          props.clickEvent(1);
        }}
      >
        {'〈〈'}
      </Box> */}
      <Box
        style={{
          marginLeft: '0.3rem',
          marginRight: '0.7rem',
          color: '#717171',
          cursor: props.currentPage == 1 ? 'default' : 'pointer',
          fontWeight: 'bold',
        }}
        onClick={() => {
          if (props.currentPage > 1) {
            props.clickEvent(props.currentPage - 1);
          }
        }}
      >
        <img
          src={iconArrowDown}
          alt="iconArrowDown"
          style={{
            width: '1.5rem',
            height: '1.5rem',
            transform: 'rotate(270deg)',
          }}
        />
      </Box>
      <Box style={{display: 'flex', justifyContent: 'space-evenly'}}>
        {visiblePage.map((item, index) => {
          const selected = props.currentPage == item;
          return (
            <Box
              key={'pageBtn' + index}
              size="small"
              sx={{
                marginLeft: '0.3rem',
                marginRight: '0.3rem',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                cursor: 'pointer',
                color: selected ? 'black' : '#A8B1B7',
                backgroundColor: selected ? '#F2F4F5' : 'white',
                fontSize: '1rem',
                fontWeight: selected ? 'bold' : 'normal',
              }}
              onClick={() => {
                props.clickEvent(item);
              }}
            >
              {item}
            </Box>
          );
        })}
      </Box>
      <Box
        style={{
          marginLeft: '0.7rem',
          marginRight: '0.3rem',
          color: '#717171',
          cursor: props.currentPage == props.totalPage ? 'default' : 'pointer',
          fontWeight: 'bold',
        }}
        onClick={() => {
          if (props.currentPage < props.totalPage) {
            props.clickEvent(props.currentPage + 1);
          }
        }}
      >
        <img
          src={iconArrowDown}
          alt="iconArrowDown"
          style={{
            width: '1.5rem',
            height: '1.5rem',
            transform: 'rotate(90deg)',
          }}
        />
      </Box>
      {/* <Box
        style={{
          marginRight: '0.3rem',
          letterSpacing: '-10px',
          paddingRight: '10px',
          color: '#717171',
          cursor: props.currentPage == props.totalPage ? 'default' : 'pointer',
          fontWeight: 'bold',
        }}
        onClick={() => {
          props.clickEvent(props.totalPage);
        }}
      >
        {'〉〉'}
      </Box> */}
    </Box>
  );
};
PaginationItem.propTypes = {
  totalPage: PropTypes.number,
  currentPage: PropTypes.number,
  clickEvent: PropTypes.func,
};
export default PaginationItem;
