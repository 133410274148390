import React from 'react';
import {Box, Button} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';
import smile from 'asset/image/icon_smile.png';
import PropTypes from 'prop-types';
const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '80%',
          textAlign: 'left',
          borderRadius: '20px',
          background: 'white',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box
          style={{
            fontSize: '1.2rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            marginTop: '1.5rem',
            color: '#00C500',
            textAlign: 'center',
          }}
        >
          인증 요청을 보냈습니다
        </Box>
        <Box
          style={{fontSize: '0.9rem', color: '#49545C', textAlign: 'center'}}
        >
          {
            '선생님이 인증을 수락하시면 [나의 강의실]에서 과제를 받을 수 있습니다.'
          }
        </Box>
        <Box
          style={{fontSize: '0.9rem', color: '#49545C', textAlign: 'center'}}
        >
          {'연결 현황은 [계정관리 > 연결된 선생님]에서  확인할 수 있습니다.'}
        </Box>
        <Button
          style={{
            marginTop: '1.5rem',
            backgroundColor: '#00C500',
            width: '100%',
            color: 'white',
            fontSize: 'calc(1rem + 0.2vw)',
            fontWeight: 'bold',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            borderRadius: '10px',
          }}
          onClick={() => {
            props.cancelEvent();
          }}
        >
          확인
        </Button>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  cancelEvent: PropTypes.func,
};
export default ItemView;
