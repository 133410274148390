import React from 'react';
import PropTypes from 'prop-types';
import {Chip} from '@mui/material';

const ItemView = props => {
  if (props.subtype == 'good') {
    return (
      <Chip
        label="좋아요"
        variant="filled"
        size="small"
        key={'good'}
        style={{
          marginTop: '0.2rem',
          fontSize: '0.875rem',
          height: '1.5rem',
          color: 'white',
          backgroundColor: '#ed9e00',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
        }}
      />
    );
  } else if (props.subtype == 'comment') {
    return (
      <Chip
        label="코멘트"
        variant="filled"
        size="small"
        key={'Comment'}
        style={{
          marginTop: '0.2rem',
          fontSize: '0.875rem',
          height: '1.5rem',
          color: 'white',
          backgroundColor: 'gray',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
        }}
      />
    );
  } else if (props.subtype == 'fix') {
    return (
      <Chip
        label="고쳐요"
        variant="filled"
        size="small"
        key={'fix'}
        style={{
          marginTop: '0.2rem',
          fontSize: '0.875rem',
          height: '1.5rem',
          color: 'white',
          backgroundColor: '#2a56c6',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
        }}
      />
    );
  } else if (props.subtype == 'point') {
    return (
      <Chip
        label="포인트"
        variant="filled"
        size="small"
        key={'point'}
        style={{
          marginTop: '0.2rem',
          fontSize: '0.875rem',
          height: '1.5rem',
          color: 'rgba(255, 255, 255, 1)',
          backgroundColor: 'green',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
        }}
      />
    );
  } else if (props.subtype == 'help') {
    return (
      <Chip
        label="도움말"
        variant="outlined"
        size="small"
        key={'help'}
        style={{
          marginTop: '0.2rem',
          fontSize: '0.875rem',
          height: '1.5rem',
          color: 'rgba(0, 0, 0, 1)',
          backgroundColor: 'white',
          border: '1px solid rgba(0, 0, 0, 1)',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
        }}
      />
    );
  } else {
    return (
      <Chip
        label="포인트"
        variant="filled"
        size="small"
        style={{
          marginTop: '0.2rem',
          fontSize: '0.875rem',
          height: '1.5rem',
          color: 'rgba(255, 255, 255, 1)',
          backgroundColor: 'green',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
        }}
      />
    );
  }
};
ItemView.propTypes = {
  subtype: PropTypes.string,
};

export default ItemView;
