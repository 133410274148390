import React from 'react';
import {Button, Box} from '@mui/material';
import PropTypes from 'prop-types';
import CopyIcon from 'asset/imageV2/icon_copy.svg';

const RewriteOverlay = props => {
  return (
    <Box
      style={{
        position: 'fixed',
        bottom: '0',
        top: '0',
        right: 'calc(15% - 5rem)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: 3,
      }}
    >
      <Button
        style={{
          width: '5rem',
          height: '5rem',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#00C500',
          color: 'black',
          fontSize: '0.8rem',
          fontWeight: 'bold',
        }}
        onClick={() => {
          props.resultEvent();
        }}
      >
        <img
          src={CopyIcon}
          alt="CopyIcon"
          style={{
            width: '1.2rem',
            height: '1.2rem',
          }}
        />
        <Box
          style={{
            color: 'white',
          }}
        >
          {'이어쓰기'}
        </Box>
      </Button>
    </Box>
  );
};
RewriteOverlay.propTypes = {
  resultEvent: PropTypes.func,
};
export default RewriteOverlay;
