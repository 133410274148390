export const ColorUtilsAnaly1 = {
  LED: 'rgba(240, 113, 170, 0.3)',
  POS: 'rgba(46, 188, 173, 0.3)',
  CLA: 'rgba(16, 132, 48, 0.3)',
  EVD: 'rgba(138, 139, 143, 0.3)',
  COC: 'rgba(235, 228, 62, 0.3)',
  REB: 'rgba(232, 122, 60, 0.3)',
  CON: 'rgba(185, 138, 233, 0.3)',
  O: 'rgba(255, 255, 255, 0.1)',
};
export const ColorUtilsLabel1 = {
  '화제 제시': 'rgba(240, 113, 170, 0.7)',
  주장: 'rgba(46, 188, 173, 0.7)',
  근거: 'rgba(16, 132, 48, 0.7)',
  예시: 'rgba(138, 139, 143, 0.7)',
  '주장 반박': 'rgba(235, 228, 62, 0.7)',
  '반박 재반박': 'rgba(232, 122, 60, 0.7)',
  결론: 'rgba(185, 138, 233, 0.7)',
};
export const ColorUtilsAnaly2 = {
  LED: 'rgba(240, 113, 170, 0.3)',
  POS: 'rgba(16, 132, 48, 0.3)',
  CLA: 'rgba(16, 132, 48, 0.3)',
  EVD: 'rgba(255, 255, 255, 0)',
  COC: 'rgba(255, 255, 255, 0)',
  REB: 'rgba(255, 255, 255, 0)',
  CON: 'rgba(185, 138, 233, 0.3)',
  O: 'rgba(255, 255, 255, 0)',
};
export const ColorUtilsLabel2 = {
  '화제 제시': 'rgba(240, 113, 170, 0.7)',
  '중심 내용': 'rgba(16, 132, 48, 0.7)',
  마무리: 'rgba(185, 138, 233, 0.7)',
};

export const Palette = {
  white: '#FFFFFF',
  green1: '#00C500',
  green2: '#DCF0C7',
  green3: '#D3E7D3',
  green4: '#1C5131',
  green5: '#068C5E',
  green6: '#DDEFC4',
  green7: 'rgb(45, 163, 160)',
  green8: '#3db49e',
  yellow1: '#FEF9EC',
  grey1: 'rgba(108, 108, 108, 0.721)',
  grey2: '#4E4E4E',
  grey3: '#565656',
  orange1: '#FF8414',
  orange2: '#ffe7d6',
  main: '#00C500',
  secondary: '#DCF0C7',
  background: '#FEF9EC',
  point: '#ED8E00',
};
