import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import AuthTeacherSub1 from 'view/popupMobile/AuthTeacherPopupSub1';
import AuthTeacherSub2 from 'view/popupMobile/AuthTeacherPopupSub2';

const ItemView = props => {
  const [tabStatus, setTabStatus] = useState(0);

  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '80%',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingBottom: '1rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box style={{display: 'flex', justifyContent: 'end'}}>
          <IconButton onClick={props.cancelEvent}>
            <img src={IconCancel} />
          </IconButton>
        </Box>
        <Box
          style={{
            fontSize: '1.25rem',
            fontWeight: 'bold',
            marginBottom: '0.5rem',
            marginTop: '0.5rem',
          }}
        >
          선생님 연결하기
        </Box>
        <Box style={{display: 'flex', marginTop: '1rem'}}>
          <Button
            style={{
              color: tabStatus == 0 ? 'black' : '#ADB4BA',
              borderBottom:
                tabStatus == 0 ? '1px solid black' : '1px solid #F4F6F6',
              borderRadius: 0,
              padding: '0.5rem 2rem',
              whiteSpace: 'nowrap',
              flex: 1,
            }}
            onClick={() => {
              setTabStatus(0);
            }}
          >
            인증 번호
          </Button>
          <Button
            style={{
              color: tabStatus == 1 ? 'black' : '#ADB4BA',
              borderBottom:
                tabStatus == 1 ? '1px solid black' : '1px solid #F4F6F6',
              borderRadius: 0,
              padding: '0.5rem 2rem',
              whiteSpace: 'nowrap',
              flex: 1,
            }}
            onClick={() => {
              setTabStatus(1);
            }}
          >
            이메일
          </Button>
        </Box>
        {tabStatus == 0 ? (
          <AuthTeacherSub1 confirmEvent={props.confirmEvent} />
        ) : (
          <AuthTeacherSub2 confirmEvent={props.confirmEvent} />
        )}
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  confirmEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
};
export default ItemView;
