import React from 'react';
import {Button, Box, Grid} from '@mui/material';
import {
  mainWholePage,
  mainBox1,
  mainBox2,
  iconBox,
  mainText1,
  mainText2,
  mainText3,
  mainText4,
} from 'view/main/MobileStyles';
import HeaderView from 'view/common/MobileHeader';
import FooterView from 'view/common/MobileFooter';
import imageMain2 from 'asset/imageV2/image_main2.png';
import imageMain4 from 'asset/imageV2/image_main4.png';
import iconCert2 from 'asset/imageV2/icon_cert2.png';
import iconCert3 from 'asset/imageV2/icon_cert3.png';
import iconCert4 from 'asset/imageV2/icon_cert4.png';
import iconCert5 from 'asset/imageV2/icon_cert5.png';
import iconCert6 from 'asset/imageV2/icon_cert6.png';
import imageNotebook from 'asset/imageV2/image_notebook.png';
const MainPage = () => {
  return (
    <Box style={mainWholePage}>
      <HeaderView type={1} />
      <Box style={mainBox1}>
        <Box style={mainText1}>쉽게 쓰고</Box>
        <Box style={mainText1}>함께 고치자!</Box>
        <Box mt={'1rem'} style={mainText2}>
          키위런
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <img src={imageMain4} alt={'imageMain4'} style={{width: '70%'}} />
        </Box>
      </Box>
      <Box style={mainBox2}>
        <Box mt={'3rem'} style={mainText3}>
          논술형 평가 연습
        </Box>
        <Box style={mainText4}>주장하는 글부터 자기소개서까지</Box>
        <Box mt={'1.5rem'} mb={'4rem'}>
          <img
            src={imageMain2}
            alt="imageMain2"
            style={{
              marginLeft: '10%',
              width: '80%',
              objectFit: 'contain',
            }}
          />
        </Box>

        <Box
          style={{
            width: '100%',
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '1rem',
          }}
        >
          <Box
            style={{
              marginLeft: '2rem',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert6}
                alt="iconCert6"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box style={{fontSize: '0.7875rem'}}>
              <Box>2024 국가공헌대상</Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                교육부 장관상
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              marginLeft: '2rem',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert2}
                alt="iconCert2"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box style={{fontSize: '0.7875rem'}}>
              <Box>인천교육청</Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                우수 에듀테크 제품 인증
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              marginLeft: '2rem',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert3}
                alt="iconCert3"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box style={{fontSize: '0.7875rem'}}>
              <Box>한국정보통신기술 협회</Box>
              <Box>
                GS (good soft){' '}
                <span style={{color: '#3cc5b0', fontWeight: '700'}}>
                  1등급 인증
                </span>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              marginLeft: '2rem',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert5}
                alt="iconCert5"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box style={{fontSize: '0.7875rem'}}>
              <Box>2024년 이러닝-에듀테크</Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                비즈니스 모델 최우수상
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              marginLeft: '2rem',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert4}
                alt="iconCert4"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box style={{fontSize: '0.7875rem'}}>
              <Box>19회 에듀테크 우수기업 콘테스트</Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                콘텐츠·서비스 분야 우수상
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt={'1.5rem'}>
          <img
            src={imageNotebook}
            alt="imgnotebook"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>
      </Box>

      <FooterView />
    </Box>
  );
};
export default MainPage;
