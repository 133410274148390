import {configureStore} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import ReduxThunk from 'redux-thunk';
import {persistStore} from 'redux-persist';
import {localReducer} from 'common/reducer';

export const localStore = configureStore({
  reducer: localReducer,
  middleware: process.env.REACT_APP_ENV == 'live' ? [] : [ReduxThunk, logger],
  devTools: process.env.REACT_APP_ENV == 'live' ? false : true,
});
export const localPersistor = persistStore(localStore);
