import React from 'react';
import {Button, Box} from '@mui/material';
import PropTypes from 'prop-types';
import CopyIcon from 'asset/imageV2/icon_copy.svg';

const ClipboardCopyOverlay = props => {
  const doCopy = text => {
    // 흐름 1.
    if (navigator.clipboard) {
      // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
      navigator.clipboard
        .writeText(text)
        .then(() => {
          props.resultEvent('글이 복사되었습니다.');
        })
        .catch(() => {
          props.resultEvent('복사를 다시 시도해주세요.');
          //alert('복사를 다시 시도해주세요.');
        });
    } else {
      // 흐름 2.
      if (!document.queryCommandSupported('copy')) {
        props.resultEvent('복사하기가 지원되지 않는 브라우저입니다.');
        return;
        //return alert('복사하기가 지원되지 않는 브라우저입니다.');
      }

      // 흐름 3.
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.top = 0;
      textarea.style.left = 0;
      textarea.style.position = 'fixed';

      // 흐름 4.
      document.body.appendChild(textarea);
      // focus() -> 사파리 브라우저 서포팅
      textarea.focus();
      // select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
      textarea.select();
      // 흐름 5.
      document.execCommand('copy');
      // 흐름 6.
      document.body.removeChild(textarea);
      props.resultEvent('글이 복사되었습니다.');
    }
  };

  return (
    <Box
      style={{
        position: 'fixed',
        bottom: '0',
        top: '0',
        right: 'calc(15% - 5rem)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: 3,
      }}
    >
      <Button
        style={{
          width: '5rem',
          height: '5rem',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FF9900',
          color: 'black',
          fontSize: '0.8rem',
          fontWeight: 'bold',
        }}
        onClick={() => {
          doCopy(props.link);
        }}
      >
        <img
          src={CopyIcon}
          alt="CopyIcon"
          style={{
            width: '1.2rem',
            height: '1.2rem',
          }}
        />
        <Box
          style={{
            color: 'white',
          }}
        >
          {'복사하기'}
        </Box>
      </Button>
    </Box>
  );
};
ClipboardCopyOverlay.propTypes = {
  link: PropTypes.string,
  resultEvent: PropTypes.func,
};
export default ClipboardCopyOverlay;
