import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import BubbleLeft from 'view/chat/BubbleLeft';
import BubbleRight from 'view/chat/BubbleRight';
import BubbleLoading from 'view/chat/BubbleLoading';

const ItemView = props => {
  if (props.role == 'loading') {
    return <BubbleLoading />;
  } else if (props.role == 'assistant') {
    return <BubbleLeft content={props.content} />;
  } else {
    return <BubbleRight content={props.content} />;
  }
};
ItemView.propTypes = {
  content: PropTypes.string,
  role: PropTypes.string,
};
export default ItemView;
