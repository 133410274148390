import React from 'react';
import RouteRouter from 'common/router';
import 'asset/css/App.css';
import usePreventZoom from 'common/utils/PreventZoom';
import LoginCookie from 'common/utils/LoginCookie';
import CheckVersion from 'common/utils/CheckVersion';
import A2HS from 'common/utils/AddHome';
function App() {
  usePreventZoom();
  LoginCookie();
  CheckVersion();
  A2HS();
  return (
    <div key={'App'} className="app" style={{width: '98vw'}}>
      <RouteRouter />
    </div>
  );
}

export default App;
