import {combineReducers} from 'redux';
import authReducer from './auth';
import popupReducer from './popup';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const reducer1 = combineReducers({
  authReducer,
  popupReducer,
});
const persistConfig1 = {
  key: 'root',
  storage: storage,
  whitelist: ['authReducer', 'popupReducer'],
};
export const localReducer = persistReducer(persistConfig1, reducer1);
