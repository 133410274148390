import {Box, Button, TextField} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {modifyAccountInfo, removeLogin} from 'common/reducer/auth';
import React, {useState} from 'react';
import APIUtils from 'common/utils/APIUtils';
import logoGoogle from 'asset/image/logo_google.png';
import logoNaver from 'asset/image/logo_naver.png';
import logoKakao from 'asset/image/logo_kakao.png';
import emojiRegex from 'emoji-regex';
import PropTypes from 'prop-types';
import AccountLeavePopup from 'view/popup/AccountLeave';
import {useNavigate} from 'react-router-dom';

export const FragmentView = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);

  const [accountLeaveVisibility, setAccountLeaveVisibility] = useState(false);
  function accountLeaveCancel() {
    setAccountLeaveVisibility(false);
  }
  function accountLeaveComplete() {
    APIUtils.LoggerLog(
      authReducer.user_id,
      'keewil',
      'info',
      window.location.pathname,
      'logout_and_navigate_home',
    );
    setAccountLeaveVisibility(false);
    dispatch(removeLogin());
    navigate('/');
  }
  const [nameText, setNameText] = useState(authReducer.student_name);
  const handleName = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 50) {
        inputVal = inputVal.substring(0, 50);
      }
      setNameText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const [currentPW, setCurrentPW] = useState('');
  const handleCurrentPW = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 20) {
        inputVal = inputVal.substring(0, 20);
        setModifyResultMessage('20자 이상 입력하실 수 없습니다.');
        setModifyResultColor('#FF0000');
      }
      setCurrentPW(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const [newPW, setNewPW] = useState('');
  const handleNewPW = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 20) {
        inputVal = inputVal.substring(0, 20);
        setModifyResultMessage('20자 이상 입력하실 수 없습니다.');
        setModifyResultColor('#FF0000');
      }
      setNewPW(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const [confirmPW, setConfirmPW] = useState('');
  const handleConfirmPW = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 20) {
        inputVal = inputVal.substring(0, 20);
        setModifyResultMessage('20자 이상 입력하실 수 없습니다.');
        setModifyResultColor('#FF0000');
      }
      setConfirmPW(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const [modifyResultMessage, setModifyResultMessage] = useState('');
  const [modifyResultColor, setModifyResultColor] = useState('');

  function saveName() {
    if (nameText != authReducer.student_name) {
      const modifyResult = async (student_id, new_name) => {
        try {
          const response = await APIUtils.AccountModify(student_id, new_name);
          if (response.data.ret_code == 1000) {
            dispatch(
              modifyAccountInfo({
                student_name: new_name,
              }),
            );
          }
        } catch (err) {
          console.log(err);
        }
      };
      modifyResult(authReducer.student_id, nameText);
    }
  }
  function savePassword() {
    if ((currentPW != '') & (newPW != '') && confirmPW != '') {
      const reg = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9]).{6,20}$');
      if (newPW.length < 6) {
        setModifyResultMessage('6글자 이상 입력하셔야 합니다.');
        setModifyResultColor('#FF0000');
        return;
      } else if (newPW != confirmPW) {
        setModifyResultMessage('새 비밀번호가 일치하지 않습니다.');
        setModifyResultColor('#FF0000');
        return;
      } else if (!reg.test(newPW)) {
        setModifyResultMessage('영문과 숫자가 포함된 비밀번호를 입력해 주세요');
        setModifyResultColor('#FF0000');
        return;
      }
      const pwChangeApi = async (student_id, currPW, newPW) => {
        try {
          const response = await APIUtils.AccountPasswordChange(
            student_id,
            currPW,
            newPW,
          );
          if (response.data.ret_code == 1000) {
            setModifyResultMessage('비밀번호 변경 완료');
            setModifyResultColor('');
          } else if (response.data.ret_code == 2004) {
            setModifyResultMessage(
              '현재 비밀번호를 다시 확인하세요. 잘못 입력했습니다.',
            );
            setModifyResultColor('#FF0000');
          }
        } catch (err) {
          console.log(err);
        }
      };
      pwChangeApi(authReducer.student_id, currentPW, newPW);
    }
    setModifyResultMessage('');
    setModifyResultColor('');
  }
  function regexTest() {
    const reg = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9]).{6,20}$');
    return reg.test(currentPW);
  }
  function getAccountLogo() {
    if (authReducer.path == 'google') {
      return (
        <img
          src={logoGoogle}
          alt={'logoGoogle'}
          style={{
            height: '30px',
            borderRadius: '30px',
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
          }}
        />
      );
    } else if (authReducer.path == 'naver') {
      return (
        <img
          src={logoNaver}
          alt={'logoNaver'}
          style={{
            height: '30px',
            borderRadius: '30px',
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
          }}
        />
      );
    } else if (authReducer.path == 'kakao') {
      return (
        <img
          src={logoKakao}
          alt={'logoKakao'}
          style={{
            height: '30px',
            borderRadius: '30px',
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
          }}
        />
      );
    }
    return <></>;
  }
  return (
    <Box style={{marginTop: '3rem'}}>
      <AccountLeavePopup
        visibility={accountLeaveVisibility}
        sentEmail={authReducer.student_email}
        completeEvent={accountLeaveComplete}
        cancelEvent={accountLeaveCancel}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '6rem',
        }}
      >
        <Box style={{display: 'flex', alignItems: 'center'}}>
          <Box>{getAccountLogo()}</Box>
          <Box style={{marginLeft: '1rem', fontSize: '1rem'}}>
            {authReducer.student_email}
          </Box>
        </Box>
        <Button
          style={{
            display: authReducer.path == 'school' ? 'none' : '',
            color: 'black',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            borderRadius: '2rem',
            border: '1.5px solid #e9ebed',
            backgroundColor: '#e9ebed',
            padding: '0.8rem 1.5rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setAccountLeaveVisibility(true);
          }}
        >
          계정 탈퇴
        </Button>
      </Box>
      <Box
        style={{
          marginTop: '3rem',
          marginBottom: '1.5rem',
          fontSize: '1.125rem',
          fontWeight: 'bold',
        }}
      >
        사용자 이름
      </Box>
      <TextField
        autoComplete="off"
        variant="outlined"
        fullWidth
        label={nameText == '' ? '선생님께 보이는 이름입니다.' : ''}
        InputProps={{
          style: {
            backgroundColor: '#F7F8FA',
            border: '1px solid #E9EBED',
            fontSize: '1rem',
            borderRadius: '0.625rem',
          },
        }}
        InputLabelProps={{
          style: {
            color: '#ADB4BA',
          },
          shrink: false,
        }}
        value={nameText}
        onChange={handleName}
      ></TextField>
      <Button
        fullWidth
        style={{
          marginTop: '2.5rem',
          color: 'white',
          fontSize: '0.875rem',
          fontWeight: 'bold',
          backgroundColor: '#00C500',
          borderRadius: '2rem',
          padding: '0.8rem 0rem',
        }}
        onClick={() => {
          saveName();
        }}
      >
        이름 변경하기
      </Button>
      <Box style={{display: authReducer.path == 'school' ? '' : 'none'}}>
        <Box
          style={{
            marginTop: '3rem',
            marginBottom: '1rem',
            fontSize: '1.125rem',
            fontWeight: 'bold',
          }}
        >
          비밀번호 변경
        </Box>
        <TextField
          autoComplete="off"
          variant="outlined"
          type="password"
          fullWidth
          label={currentPW == '' ? '현재 비밀번호' : ''}
          InputProps={{
            style: {
              backgroundColor: '#F7F8FA',
              border: '1px solid #E9EBED',
              fontSize: '1rem',
              borderRadius: '0.625rem',
            },
          }}
          InputLabelProps={{
            style: {
              color: '#ADB4BA',
            },
            shrink: false,
          }}
          value={currentPW}
          onChange={handleCurrentPW}
        ></TextField>
        <TextField
          autoComplete="off"
          variant="outlined"
          type="password"
          fullWidth
          label={newPW == '' ? '변경할 비밀번호 (영어, 숫자 조합 6자리)' : ''}
          InputProps={{
            style: {
              backgroundColor: '#F7F8FA',
              border: '1px solid #E9EBED',
              fontSize: '1rem',
              borderRadius: '0.625rem',
            },
          }}
          InputLabelProps={{
            style: {
              color: '#ADB4BA',
            },
            shrink: false,
          }}
          value={newPW}
          onChange={handleNewPW}
        ></TextField>
        <TextField
          autoComplete="off"
          variant="outlined"
          type="password"
          fullWidth
          label={
            confirmPW == '' ? '변경 비밀번호 확인 (영어, 숫자 조합 6자리)' : ''
          }
          InputProps={{
            style: {
              backgroundColor: '#F7F8FA',
              border: '1px solid #E9EBED',
              fontSize: '1rem',
              borderRadius: '0.625rem',
            },
          }}
          InputLabelProps={{
            style: {
              color: '#ADB4BA',
            },
            shrink: false,
          }}
          value={confirmPW}
          onChange={handleConfirmPW}
        ></TextField>{' '}
        <Box
          style={{
            marginTop: '1rem',
            fontSize: '0.8rem',
            color: modifyResultColor,
          }}
        >
          {modifyResultMessage}
        </Box>
        <Button
          fullWidth
          style={{
            marginTop: '2.5rem',
            color: 'white',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            backgroundColor: '#00C500',
            borderRadius: '2rem',
            padding: '0.8rem 0rem',
          }}
          onClick={() => {
            savePassword();
          }}
        >
          비밀번호 변경하기
        </Button>
      </Box>
    </Box>
  );
};
export default FragmentView;
