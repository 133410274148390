import {Box, Button} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import APIUtils from 'common/utils/APIUtils';
import TeacherView1 from 'view/mypage/TeacherView1';

export const FragmentView = props => {
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);
  const [teacherList, setTeacherList] = useState([]);
  function getTeacherInfo() {
    const getTeacherI = async () => {
      try {
        const response = await APIUtils.TeacherList(authReducer.student_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getTeacherI().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setTeacherList(res.data.teacher_list);
      }
    });
  }
  useEffect(() => {
    getTeacherInfo();
  }, []);
  function showTeacherList() {
    if (teacherList.length == 0) {
      return <Box>연결된 선생님이 없습니다. </Box>;
    } else {
      return (
        <Box>
          {teacherList.map((val, idx) => {
            return (
              <TeacherView1 key={'teacherVeiw' + idx} teacher_info={val} />
            );
          })}
        </Box>
      );
    }
  }
  return <Box style={{marginTop: '3rem'}}>{showTeacherList()}</Box>;
};
export default FragmentView;
