import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';

const ItemView = props => {
  return (
    <Box
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'start',
        marginTop: '2rem',
        marginBottom: '1rem',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        style={{
          fontWeight: '700',
          color: 'white',
          backgroundColor: '#ff9900',
          padding: '0.125rem 0.5rem',
          borderRadius: '0.3125rem',
          marginBottom: '0.5rem',
        }}
      >
        문단 {props.paragraphIndex}
      </Box>
      <Box
        style={{
          width: 'calc(100% - 3rem)',
          // flex: 1,
          border: '2px solid var(--Secondary, #F90)',
          borderRadius: '0.625rem',
          backgroundColor: '#FFFDFB',
          padding: '1.5rem',
          fontSize: 'calc(0.6rem + 0.5vw)',
          wordWrap: 'break-word',
        }}
      >
        {props.text}
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  paragraphIndex: PropTypes.number,
  text: PropTypes.array,
};
export default ItemView;
