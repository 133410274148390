import React, {useEffect, useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import Main from 'page/main/Main';
import Mypage from 'page/mypage/Mypage';
import Login from 'page/login/Login';
import EnterpriseLogin from 'page/login/EnterpriseLogin';
import Writing1 from 'page/writing/Writing1';
import Writing2 from 'page/writing/Writing2';
import Result from 'page/result/Result';
import Rewrite1 from 'page/result/Rewrite1';
import Rewrite2 from 'page/result/Rewrite2';
import RedirectTask from 'page/redirect/RedirectTask';
import NotFound from 'page/error/NotFound';
import Patch from 'page/error/ServerPatch';
import ResultSaved from 'page/result/ResultSaved';
import ResultSubmit from 'page/result/ResultSubmit';
import ResultPractice from 'page/result/ResultPractice';

import MobileMain from 'page/main/MobileMain';
import MobileWriting from 'page/writing/MobileWriting';
import MobileLogin from 'page/login/MobileLogin';
import MobileLoginEnterprise from 'page/login/MobileEnterpriseLogin';
import MobileMypage from 'page/mypage/MobileMypage';

import {isMobileOnly} from 'react-device-detect';
import PrivateWrapper from 'common/router/PrivateWrapper';
import APIUtils from 'common/utils/APIUtils';
import {useContext} from 'react';
import {IPInfoContext} from 'ip-info-react';

const RouterIndex = () => {
  const userInfo = useContext(IPInfoContext);
  const [showPatch, setShowPatch] = useState(false);
  const checkAnnouncement = async () => {
    if (userInfo.ip == '211.109.168.54') {
      return;
    }
    const announcementCheck = async () => {
      try {
        const response = await APIUtils.CheckAnnouncement();
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    announcementCheck()
      .then(r => {
        if (r == undefined) {
          setShowPatch(false);
          return;
        }
        if (r.data.downtime_announce != null) {
          setShowPatch(true);
        } else {
          setShowPatch(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  async function CheckAnnouncement() {
    useEffect(() => {
      checkAnnouncement();

      const handleFocusChange = () => {
        checkAnnouncement();
      };
      window.addEventListener('focus', handleFocusChange);
      return () => {
        window.removeEventListener('focus', handleFocusChange);
      };
    }, []);
  }
  CheckAnnouncement();
  if (showPatch) {
    return (
      <Routes>
        <Route path="/*" element={<Patch />}></Route>
      </Routes>
    );
  }
  if (isMobileOnly) {
    return (
      <Routes>
        <Route path="/" element={<MobileMain />}></Route>
        <Route
          path="/mypage"
          element={
            <PrivateWrapper>
              <MobileMypage />
            </PrivateWrapper>
          }
        ></Route>

        <Route
          path="/writing"
          element={
            <PrivateWrapper>
              <MobileWriting />
            </PrivateWrapper>
          }
        ></Route>
        <Route path="/login" element={<MobileLogin />}></Route>
        <Route
          path="/login/enterprise"
          element={<MobileLoginEnterprise />}
        ></Route>
        <Route path="/redirect/task/:key" element={<RedirectTask />}></Route>
        <Route path="/*" element={<NotFound />}></Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Main />}></Route>
      <Route
        path="/mypage"
        element={
          <PrivateWrapper>
            <Mypage />
          </PrivateWrapper>
        }
      ></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/login/enterprise" element={<EnterpriseLogin />}></Route>
      {/* <Route path="/writing" element={<Writing />}></Route> */}
      <Route
        path="/writing1"
        element={
          <PrivateWrapper>
            <Writing1 />
          </PrivateWrapper>
        }
      ></Route>
      <Route
        path="/writing2"
        element={
          <PrivateWrapper>
            <Writing2 />
          </PrivateWrapper>
        }
      ></Route>
      <Route
        path="/result"
        element={
          <PrivateWrapper>
            <Result />
          </PrivateWrapper>
        }
      ></Route>
      <Route
        path="/result/saved"
        element={
          <PrivateWrapper>
            <ResultSaved />
          </PrivateWrapper>
        }
      ></Route>
      <Route
        path="/result/submit"
        element={
          <PrivateWrapper>
            <ResultSubmit />
          </PrivateWrapper>
        }
      ></Route>
      <Route
        path="/result/practice"
        element={
          <PrivateWrapper>
            <ResultPractice />
          </PrivateWrapper>
        }
      ></Route>
      <Route
        path="/rewrite1"
        element={
          <PrivateWrapper>
            <Rewrite1 />
          </PrivateWrapper>
        }
      ></Route>
      <Route
        path="/rewrite2"
        element={
          <PrivateWrapper>
            <Rewrite2 />
          </PrivateWrapper>
        }
      ></Route>
      <Route path="/redirect/task/:key" element={<RedirectTask />}></Route>
      <Route path="/*" element={<NotFound />}></Route>
    </Routes>
  );
};
export default RouterIndex;
