import React from 'react';
import {Box} from '@mui/material';
import pageImage from 'asset/image/error_code_400.png';

const ErrorPage = () => {
  return (
    <Box
      style={{marginTop: '10rem', display: 'flex', justifyContent: 'center'}}
    >
      <img src={pageImage} alt={'pageImage'} style={{width: '50%'}} />
    </Box>
  );
};
export default ErrorPage;
