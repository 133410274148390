import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import {loginBox} from 'view/login/MobileStyles';

const NaverButton = props => {
  const {naver} = window;
  const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
  const NAVER_CALLBACK_URL = process.env.REACT_APP_NAVER_REDIRECT_URI;

  const initializeNaverLogin = () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: NAVER_CLIENT_ID,
      callbackUrl: NAVER_CALLBACK_URL,
      isPopup: false,
      loginButton: {color: 'green', type: 3, height: 58},
      callbackHandle: true,
      auth_Type: 'reauthenticate',
    });
    naverLogin.init();
  };

  const userAccessToken = () => {
    window.location.href.includes('access_token') && getToken();
  };

  const getToken = () => {
    const token = window.location.href.split('=')[1].split('&')[0];
    props.onSuccess(token);
  };

  useEffect(() => {
    initializeNaverLogin();
    userAccessToken();
  }, []);
  const login = () => {
    var naverLogin = document.getElementById('naverIdLogin').firstChild;
    naverLogin.click();
  };
  return (
    <>
      <div id="naverIdLogin" style={{display: 'none'}} />
      <Button style={loginBox.coloredBox('#6ae776')} onClick={login}>
        네이버로 로그인/가입하기
      </Button>
    </>
  );
};
NaverButton.propTypes = {
  onSuccess: PropTypes.func,
};
export default NaverButton;
