import React, {useState} from 'react';
import {Box, Button} from '@mui/material';
import logo from 'asset/imageV2/logo_keewi_learn.png';
import {headerBox1} from 'view/login/Styles';
import {useNavigate} from 'react-router-dom';
import {number} from 'prop-types';
import MobileMenu from './MobileMenu';
import menuWhite from 'asset/image/icon_menu_white.png';
import menuBlack from 'asset/image/icon_menu_black.png';

const HeaderView = props => {
  const navigate = useNavigate();
  const [showing, setShowing] = useState(false);

  function showMenu() {
    if (showing) {
      return <MobileMenu clickOutside={clickedOut} />;
    }
    return <></>;
  }
  function clickedOut() {
    setShowing(false);
  }

  let backColor = 'white';
  if (props.type == 1) {
    backColor = '#00C500';
  }

  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: backColor,
      }}
    >
      {/* <PopupView text={toastText} visibility={toastVisibility} /> */}
      {showMenu()}
      <Box style={headerBox1}>
        <img
          src={logo}
          alt={'logoT'}
          style={{height: '30px'}}
          onClick={() => {
            navigate('/', {replace: true});
          }}
        />
        <Box
          onClick={() => {
            setShowing(true);
          }}
        >
          <img
            src={props.type == 1 ? menuWhite : menuBlack}
            alt={'menu'}
            style={{height: '30px'}}
          />
        </Box>
      </Box>
    </Box>
  );
};
HeaderView.propTypes = {
  type: number,
};
export default HeaderView;
