import {createSlice} from '@reduxjs/toolkit';
const initialState = {
  close_date: '',
};
export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    saveCloseDate: (state, action) => {
      state.close_date = action.payload.close_date;
    },
  },
});
export const {saveCloseDate} = popupSlice.actions;
export default popupSlice.reducer;
