import {Box, Button} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import APIUtils from 'common/utils/APIUtils';
import PropTypes from 'prop-types';
import TaskView1 from './MobileTaskView1';
import arrowLeft from 'asset/imageV2/icon_arrow_left.svg';

export const FragmentView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const [originalTaskList, setOriginalTaskList] = useState([]);
  const [filteredTaskList, setFilteredTaskList] = useState([]);

  function getTaskList() {
    const taskList = async () => {
      try {
        const response = await APIUtils.TaskList(
          authReducer.student_id,
          props.user_id,
        );
        if (response.status == 200 && response.data.ret_code == 1000) {
          setOriginalTaskList(response.data.task_list);
        }
      } catch (err) {
        console.log(err);
      }
    };
    taskList();
  }
  function getFilteredTask() {
    if (originalTaskList == undefined || originalTaskList.length == 0) {
      setFilteredTaskList([]);
      return;
    }
    let filtered = [...originalTaskList];

    filtered.sort((a, b) => {
      if (a.task_status == '진행중' && b.task_status != '진행중') return -1;
      if (a.task_status != '진행중' && b.task_status == '진행중') return 1;
      if (a.task_status == '대기' && b.task_status != '대기') return -1;
      if (a.task_status != '대기' && b.task_status == '대기') return 1;
      return new Date(b.task_end_time) - new Date(a.task_end_time);
    });
    setFilteredTaskList(filtered);
  }
  useEffect(() => {
    getTaskList();
  }, []);
  useEffect(() => {
    getFilteredTask();
  }, [originalTaskList]);
  return (
    <Box>
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem', color: '#717171'}}>
        <Button onClick={props.backEvent}>
          <img src={arrowLeft} alt="arrowLeft" />
        </Button>
        {props.teacher_name + ' 선생님'}
      </Box>
      <Box
        style={{
          color: '#717171',
          fontSize: '0.875rem',
          padding: '0 1rem',
          marginTop: '1rem',
        }}
      >
        선생님께 받은 과제입니다. 모바일에서는 초안 쓰기만을 제공합니다. AI
        피드백 확인과 과제 제출은 PC나 태블릿에서 수행할 수 있습니다.
      </Box>
      <Box
        style={{marginTop: '2rem', marginBottom: '2rem', minHeight: '13rem'}}
      >
        {filteredTaskList.map((val, idx) => {
          return <TaskView1 key={'taskview' + idx} task={val} />;
        })}
      </Box>
    </Box>
  );
};
FragmentView.propTypes = {
  user_id: PropTypes.number,
  teacher_name: PropTypes.string,
  backEvent: PropTypes.func,
};
export default FragmentView;
